import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusAnalyticsDashboardsIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.14 16.2C10.62 16.68 11.24 16.9152 12 16.9056C12.76 16.896 13.32 16.6208 13.68 16.08L18.75 8.49C18.93 8.21 18.9052 7.9548 18.6756 7.7244C18.446 7.494 18.1908 7.4692 17.91 7.65L10.32 12.72C9.78 13.08 9.4952 13.63 9.4656 14.37C9.436 15.11 9.6608 15.72 10.14 16.2ZM3.72 21.6C3.3 21.6 2.9 21.5 2.52 21.3C2.14 21.1 1.84 20.82 1.62 20.46C1.1 19.56 0.7 18.6048 0.42 17.5944C0.14 16.584 0 15.5192 0 14.4C0 12.74 0.3152 11.1848 0.9456 9.7344C1.576 8.284 2.436 7.0144 3.5256 5.9256C4.6152 4.8352 5.8896 3.9748 7.3488 3.3444C8.808 2.714 10.3584 2.3992 12 2.4C13.66 2.4 15.2152 2.7152 16.6656 3.3456C18.116 3.976 19.3856 4.836 20.4744 5.9256C21.5648 7.0152 22.4248 8.2852 23.0544 9.7356C23.684 11.186 23.9992 12.7408 24 14.4C24 15.52 23.8648 16.5752 23.5944 17.5656C23.324 18.556 22.9192 19.5208 22.38 20.46C22.16 20.82 21.86 21.1 21.48 21.3C21.1 21.5 20.7 21.6 20.28 21.6H3.72Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
