// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  LocusKnowledgeBaseIcon,
  LocusReleaseInformationIcon,
  LocusServiceHealthIcon,
  LocusSupportIcon,
} from "assets/icons/LocusIcons.js";

// ==============================|| APPLICATION MENU ITEMS ||============================== //
/** @type {LocusMenuGroup} */
const help = {
  id: "help",
  title: <FormattedMessage id="help" />,
  type: "group",
  view: ["locus", "client", "site"],
  className: "guided-tour-nav-help",
  children: [
    {
      id: "knowledge-base",
      title: <FormattedMessage id="knowledge-base" />,
      type: "item",
      icon: LocusKnowledgeBaseIcon,
      url: "https://locushub-asset.locus-robotics.com/help/index.htm",
      breadcrumbs: false,
      target: true,
      external: true,
      view: ["locus", "client", "site"],
      allowAll: true,
      className: "guided-tour-nav-knowledge-base",
    },
    {
      id: "support-portal",
      title: <FormattedMessage id="support-portal" />,
      type: "item",
      icon: LocusSupportIcon,
      url: "https://support.locusrobotics.com/",
      breadcrumbs: false,
      target: true,
      external: true,
      view: ["locus", "client", "site"],
      allowAll: true,
    },
    {
      id: "release-info",
      title: <FormattedMessage id="release-info" />,
      type: "item",
      icon: LocusReleaseInformationIcon,
      url: "help/release-info?site=:siteId",
      breadcrumbs: true,
      view: ["locus", "client", "site"],
      allowAll: true,
    },
    {
      id: "service-health",
      title: <FormattedMessage id="service-health" />,
      type: "item",
      icon: LocusServiceHealthIcon,
      url: "help/service-health?site=:siteId",
      breadcrumbs: true,
      view: ["locus", "client", "site"],
      allowAll: true,
    },
  ],
};

export default help;
