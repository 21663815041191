import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const useBackRoute = (tail) => {
  let location = useLocation();
  const [backRoute, setBackRoute] = useState("");
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const maintainSiteSearchParam = () => {
    if (searchParams.get("site")) {
      return `?site=${searchParams.get("site")}`;
    }
    return "";
  };

  useEffect(() => {
    let backRoute = location.pathname.split("/");
    if (tail) {
      backRoute = backRoute.filter((path) => {
        return path !== tail;
      });
    } else {
      backRoute.pop();
    }
    setBackRoute(backRoute.join("/") + maintainSiteSearchParam());
  }, [location]);

  return {
    goBack: () => {
      navigate(backRoute);
    },
    previousRoute: backRoute,
  };
};
