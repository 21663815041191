export const linkSX = (theme) => {
  return {
    display: "flex",
    color: theme?.palette?.primary?.main,
    textDecoration: "none",
    alignContent: "center",
    alignItems: "center",
    fontSize: 14,
  };
};

export const getNavItemURL = ({ location, navItem, url }) => {
  if (!url?.includes(":")) {
    return url;
  } else {
    return "#";
  }
};
