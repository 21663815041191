import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusHomeIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.8 22.2C4.14 22.2 3.5748 21.9648 3.1044 21.4944C2.634 21.024 2.3992 20.4592 2.4 19.8V9.00001C2.4 8.62001 2.4852 8.26001 2.6556 7.92001C2.826 7.58001 3.0608 7.30001 3.36 7.08001L10.56 1.68001C10.78 1.52001 11.01 1.40001 11.25 1.32001C11.49 1.24001 11.74 1.20001 12 1.20001C12.26 1.20001 12.51 1.24001 12.75 1.32001C12.99 1.40001 13.22 1.52001 13.44 1.68001L20.64 7.08001C20.94 7.30001 21.1752 7.58001 21.3456 7.92001C21.516 8.26001 21.6008 8.62001 21.6 9.00001V19.8C21.6 20.46 21.3648 21.0252 20.8944 21.4956C20.424 21.966 19.8592 22.2008 19.2 22.2H14.4V13.8H9.6V22.2H4.8Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
