import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusPermisionsIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.64 6.60001H15.24V3.36001C15.24 2.17201 14.268 1.20001 13.08 1.20001H10.92C9.732 1.20001 8.76 2.17201 8.76 3.36001V6.60001H3.36C2.172 6.60001 1.2 7.57201 1.2 8.76001V20.64C1.2 21.828 2.172 22.8 3.36 22.8H20.64C21.828 22.8 22.8 21.828 22.8 20.64V8.76001C22.8 7.57201 21.828 6.60001 20.64 6.60001ZM8.76 12C9.6564 12 10.38 12.7236 10.38 13.62C10.38 14.5164 9.6564 15.24 8.76 15.24C7.8636 15.24 7.14 14.5164 7.14 13.62C7.14 12.7236 7.8636 12 8.76 12ZM12 18.48H5.52V18.0156C5.52 17.3676 5.9088 16.7736 6.5136 16.5144C7.2048 16.212 7.9608 16.05 8.76 16.05C9.5592 16.05 10.3152 16.212 11.0064 16.5144C11.6004 16.7736 12 17.3568 12 18.0156V18.48ZM13.08 8.76001H10.92V3.36001H13.08V8.76001ZM17.67 16.86H14.97C14.5272 16.86 14.16 16.4928 14.16 16.05C14.16 15.6072 14.5272 15.24 14.97 15.24H17.67C18.1128 15.24 18.48 15.6072 18.48 16.05C18.48 16.4928 18.1128 16.86 17.67 16.86ZM17.67 13.62H14.97C14.5272 13.62 14.16 13.2528 14.16 12.81C14.16 12.3672 14.5272 12 14.97 12H17.67C18.1128 12 18.48 12.3672 18.48 12.81C18.48 13.2528 18.1128 13.62 17.67 13.62Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
