export const SnackbarType = {
  Negative: {
    variant: "alert",
    alert: {
      color: "error",
    },
  },
  Success: {
    variant: "alert",
    alert: {
      color: "success",
    },
  },
  Info: {
    variant: "alert",
    alert: {
      color: "primary",
    },
  },
};
