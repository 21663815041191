import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusReportsIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.4 22.8H1.2C0.54 22.8 0 22.26 0 21.6V9.6C0 8.94 0.54 8.4 1.2 8.4H5.4C6.06 8.4 6.6 8.94 6.6 9.6V21.6C6.6 22.26 6.06 22.8 5.4 22.8ZM14.1 1.2H9.9C9.24 1.2 8.7 1.74 8.7 2.4V21.6C8.7 22.26 9.24 22.8 9.9 22.8H14.1C14.76 22.8 15.3 22.26 15.3 21.6V2.4C15.3 1.74 14.76 1.2 14.1 1.2ZM22.8 10.8H18.6C17.94 10.8 17.4 11.34 17.4 12V21.6C17.4 22.26 17.94 22.8 18.6 22.8H22.8C23.46 22.8 24 22.26 24 21.6V12C24 11.34 23.46 10.8 22.8 10.8Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
