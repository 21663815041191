import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusClientsIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.76 17.88C21.456 16.668 21.9 15.204 21.372 13.5C20.736 11.436 18.924 9.84 16.764 9.636C15.963 9.5505 15.153 9.64537 14.3935 9.91365C13.6339 10.1819 12.944 10.6168 12.3744 11.1864C11.8048 11.756 11.3699 12.4459 11.1017 13.2055C10.8334 13.965 10.7385 14.775 10.824 15.576C11.04 17.724 12.624 19.548 14.688 20.184C16.404 20.712 17.856 20.268 19.068 19.572L22.068 22.572C22.536 23.04 23.28 23.04 23.748 22.572C23.8592 22.4622 23.9474 22.3314 24.0077 22.1872C24.0679 22.043 24.0989 21.8883 24.0989 21.732C24.0989 21.5757 24.0679 21.421 24.0077 21.2768C23.9474 21.1326 23.8592 21.0018 23.748 20.892L20.76 17.88ZM16.2 18C14.52 18 13.2 16.68 13.2 15C13.2 13.32 14.52 12 16.2 12C17.88 12 19.2 13.32 19.2 15C19.2 16.68 17.88 18 16.2 18ZM12 21.6V24C5.376 24 0 18.624 0 12C0 5.376 5.376 0 12 0C17.808 0 22.644 4.128 23.76 9.6H21.276C20.9017 8.15021 20.1935 6.80806 19.208 5.68082C18.2224 4.55358 16.9868 3.6725 15.6 3.108V3.6C15.6 4.92 14.52 6 13.2 6H10.8V8.4C10.8 9.06 10.26 9.6 9.6 9.6H7.2V12H9.6V15.6H8.4L2.652 9.852C2.496 10.548 2.4 11.256 2.4 12C2.4 17.292 6.708 21.6 12 21.6Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
