import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusMaxBotIcon = ({ color, fontSize }) => {
  return (
    <SvgIcon
      color={color ? color : ""}
      fontSize={fontSize ? fontSize : "medium"}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5556 7H0.444444C0.197531 7 0 7.19753 0 7.44444C0 7.69136 0.197531 7.88889 0.444444 7.88889H0.592593V9.5679C0.592593 9.64197 0.641975 9.74074 0.716049 9.79012L2.49383 10.9753C2.51852 9.83951 3.45679 8.95062 4.61728 8.95062C5.80247 8.95062 6.74074 9.88889 6.74074 11.0494C6.74074 11.3951 6.59259 11.7901 6.5679 11.8148V11.8395H6.59259H13.4074H13.4321V11.8148C13.4321 11.8096 13.4244 11.7879 13.4123 11.7537L13.4123 11.7537C13.3669 11.6257 13.2593 11.3222 13.2593 11.0494C13.2593 9.88889 14.2222 8.95062 15.3827 8.95062C16.5432 8.95062 17.4815 9.8642 17.5062 10.9753L19.284 9.79012C19.3333 9.74074 19.4074 9.64197 19.4074 9.5679V7.88889H19.5556C19.8025 7.88889 20 7.69136 20 7.44444C20 7.19753 19.8025 7 19.5556 7ZM4.61728 12.4321C5.40821 12.4321 6.04938 11.802 6.04938 11.0247C6.04938 10.2474 5.40821 9.61728 4.61728 9.61728C3.82636 9.61728 3.18518 10.2474 3.18518 11.0247C3.18518 11.802 3.82636 12.4321 4.61728 12.4321ZM16.8148 11.0247C16.8148 11.802 16.1736 12.4321 15.3827 12.4321C14.5918 12.4321 13.9506 11.802 13.9506 11.0247C13.9506 10.2474 14.5918 9.61728 15.3827 9.61728C16.1736 9.61728 16.8148 10.2474 16.8148 11.0247Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
