import { lazy } from "react";
import AuthGuard from "../utils/route-guard/AuthGuard";
import { MainLayout } from "../layout/MainLayout/MainLayout";
import { Loadable } from "components/Loadable/Loadable";
import componentLoader from "../utils/helpers/componentLoader";

const LoginPermissions = Loadable(
  lazy(() =>
    componentLoader(() => import("views/authentication/LoginPermissions")),
  ),
);

// ==============================|| AUTH ROUTING ||============================== //

const PermissonRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/login-permissions",
      element: <LoginPermissions />,
    },
  ],
};

export default PermissonRoutes;
