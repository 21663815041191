import { useSelector } from "../../store";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { Stack } from "@mui/system";
import {
  Input,
  List,
  ListItemButton,
  Popover,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { linkSX } from "./Breadcrumb.helpers";
import { useTheme } from "@mui/material/styles";
import { CheckAccess } from "../../utils/helpers";
import {
  LOCUS_CONFIGURATION_ADMIN,
  LOCUS_CUSTOMER_USER_MANAGER,
  LOCUS_USER_MANAGER,
  SUPER_ADMIN,
  USER_MANAGER,
} from "../../utils/constants/user";
import { useMemo } from "react";
import { hasAccessToAllSites } from "../../routes/ProtectedRoute";

export const SitePopover = ({
  handleSearchSites,
  handleSelectSite,
  filterSites,
}) => {
  const theme = useTheme();
  const { selectedSite } = useSelector((state) => state.customer);
  const { activeUser } = useSelector((state) => state.user);

  const handlePopoverClose = (popupState) => {
    setTimeout(() => {
      handleSearchSites(null);
    }, 100);
    popupState.close();
  };

  const allowClient = useMemo(() => {
    return (
      hasAccessToAllSites(activeUser?.groups) ||
      activeUser?.groups?.filter(
        (group) =>
          group.name === LOCUS_USER_MANAGER ||
          group.name === LOCUS_CUSTOMER_USER_MANAGER ||
          group.name === USER_MANAGER,
      ).length > 0
    );
  }, [activeUser]);

  return (
    <PopupState
      variant="popover"
      popupId="simple-popover"
      disableAutoFocus={true}
    >
      {(popupState) => (
        <Stack
          sx={{ cursor: "pointer" }}
          className="guided-tour-sites-selection"
        >
          <Typography sx={linkSX(theme)} {...bindTrigger(popupState)}>
            {!!selectedSite ? selectedSite?.friendlyName : "Client"}
            <ArrowDropDownIcon />
          </Typography>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={() => handlePopoverClose(popupState)}
          >
            <Stack sx={{ minWidth: 250, p: 1 }}>
              <Input
                disableUnderline
                sx={{
                  border: `1px solid ${theme.palette.grey[200]}`,
                  background: theme.palette.grey[100],
                  height: 35,
                  textIndent: 7,
                  borderRadius: 1,
                }}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onChange={(event) => {
                  handleSearchSites(event?.target?.value);
                }}
              />
            </Stack>
            <Stack sx={{ maxHeight: 400 }}>
              {
                <List component="ul">
                  {allowClient && (
                    <ListItemButton
                      sx={{ fontSize: 14 }}
                      selected={!selectedSite}
                      onClick={async () => {
                        await handleSelectSite(null);
                        handlePopoverClose(popupState);
                      }}
                    >
                      Client
                    </ListItemButton>
                  )}
                  {filterSites
                    ?.filter((site) => {
                      if (
                        CheckAccess(
                          [LOCUS_CONFIGURATION_ADMIN, SUPER_ADMIN],
                          activeUser,
                        )
                      ) {
                        return true;
                      }
                      return site?.status !== "Decommissioned";
                    })
                    .map((site) => (
                      <ListItemButton
                        key={site?.friendlyName}
                        sx={{ fontSize: 14 }}
                        selected={
                          selectedSite?.friendlyName === site?.friendlyName
                        }
                        onClick={async () => {
                          await handleSelectSite(site);
                          handlePopoverClose(popupState);
                        }}
                      >
                        {site?.status === "Decommissioned"
                          ? `${site?.friendlyName} (Decommissioned)`
                          : site?.friendlyName}
                      </ListItemButton>
                    ))}
                </List>
              }
            </Stack>
          </Popover>
        </Stack>
      )}
    </PopupState>
  );
};
