import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export const LocusDialog = ({
  handleClose,
  title,
  message,
  children,
  open,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {title && (
        <DialogTitle id="dialog-title" align={"center"}>
          {title}
        </DialogTitle>
      )}
      {children ? (
        children
      ) : (
        <>
          {message && (
            <>
              <DialogContent>
                <DialogContentText id="dialog-description">
                  {message}
                </DialogContentText>
              </DialogContent>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};
