import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  loading: false,
};

// ==============================|| SLICE - MENU ||============================== //

const utils = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export default utils.reducer;

export const { setLoading } = utils.actions;
