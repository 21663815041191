// material-ui
import { createTheme } from "@mui/material/styles";

import defaultColor from "assets/scss/_themes-vars.module.scss";

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (navType, presetColor) => {
  let colors;
  switch (presetColor) {
    case "default":
    default:
      colors = defaultColor;
  }

  return createTheme({
    palette: {
      mode: navType,
      common: {
        black: colors.darkPaper,
      },
      primary: {
        light:
          navType === "dark" ? colors.darkPrimaryLight : colors.primaryLight,
        main: navType === "dark" ? colors.darkPrimaryMain : colors.primaryMain,
        dark: navType === "dark" ? colors.darkPrimaryDark : colors.primaryDark,
        200: navType === "dark" ? colors.darkPrimary200 : colors.primary200,
        800: navType === "dark" ? colors.darkPrimary800 : colors.primary800,
      },
      secondary: {
        light:
          navType === "dark"
            ? colors.darkSecondaryLight
            : colors.secondaryLight,
        main:
          navType === "dark" ? colors.darkSecondaryMain : colors.secondaryMain,
        dark:
          navType === "dark" ? colors.darkSecondaryDark : colors.secondaryDark,
        200: navType === "dark" ? colors.darkSecondary200 : colors.secondary200,
        800: navType === "dark" ? colors.darkSecondary800 : colors.secondary800,
      },
      error: {
        light: navType === "dark" ? colors.darkErrorText : colors.errorLight,
        200: navType === "dark" ? colors.darkErrorStroke : colors.errorLight,
        main: navType === "dark" ? colors.darkErrorMain : colors.errorMain,
        dark:
          navType === "dark" ? colors.darkErrorBackground : colors.errorDark,
      },
      orange: {
        light: colors.orangeLight,
        main: colors.orangeMain,
        dark: colors.orangeDark,
      },
      warning: {
        light:
          navType === "dark" ? colors.darkWarningText : colors.warningLight,
        200: navType === "dark" ? colors.darkWarningStroke : colors.success200,
        main: navType === "dark" ? colors.darkWarningMain : colors.warningMain,
        dark:
          navType === "dark"
            ? colors.darkWarningBackground
            : colors.warningDark,
      },
      success: {
        light:
          navType === "dark" ? colors.darkSuccessText : colors.successLight,
        200: navType === "dark" ? colors.darkSuccessStroke : colors.success200,
        main: navType === "dark" ? colors.darkSuccessMain : colors.successMain,
        dark:
          navType === "dark"
            ? colors.darkSuccessBackground
            : colors.successDark,
      },
      grey: {
        50: navType === "dark" ? colors.darkGrey800 : colors.grey50,
        100: navType === "dark" ? colors.darkGrey100 : colors.grey100,
        200: navType === "dark" ? colors.darkGrey200 : colors.grey200,
        300: navType === "dark" ? colors.darkGrey300 : colors.grey300,
        500: navType === "dark" ? colors.darkGrey500 : colors.grey500,
        600: navType === "dark" ? colors.darkGrey600 : colors.grey900,  
        700: navType === "dark" ? colors.darkGrey700 : colors.grey700,
        900: navType === "dark" ? colors.darkGrey800 : colors.grey900,
      },
      dark: {
        light: colors.darkTextPrimary,
        main: colors.darkLevel1,
        dark: colors.darkLevel2,
        800: colors.darkBackground,
        900: colors.darkPaper,
      },
      text: {
        primary: navType === "dark" ? colors.darkGrey700 : colors.grey700,
        secondary: navType === "dark" ? colors.darkGrey500 : colors.grey500,
        dark: navType === "dark" ? colors.darkGrey800 : colors.grey900,
        hint: navType === "dark" ? colors.darkGrey300 : colors.grey100,
      },
      divider: navType === "dark" ? colors.darkGrey200 : colors.grey200,
      background: {
        paper: navType === "dark" ? colors.darkPaper : colors.paper,
        default: navType === "dark" ? colors.darkPaper : colors.paper,
      },
    },
  });
};

export default Palette;
