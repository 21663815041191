/**
 * Warnings only need to be a subset of the warning message to be suppressed.
 *
 * Include a description for why the message is being suppressed and when it occurs
 */
const _WARNINGS = [
  /**
   * Force updating the devDependency version of "@googlemaps/js-api-loader" does not seem to resolve the warning since
   * `react-google-maps-api` does not accept the newly required property `mapId`
   *
   * `mapId` is required to use advanced features of `@googlemaps` and the only way to provide it is during
   * initialization of the `google.map` instance
   *
   * https://github.com/JustFly1984/react-google-maps-api/issues/3337
   * The `Marker` element will continue to be used since it is supported until February 2025 and will not be
   * discontinued (only deprecated),
   *
   * The deprecation message will be suppressed until the `react-google-maps-api` is updated to support the `mapId`
   * config and the `AdvancedMarkerElement` component
   *
   * The alternative is to remove `react-google-maps-api` entirely and use `@googlemaps` directly
   */
  "As of February 21st, 2024, google.maps.Marker is deprecated.",
  /**
   * Hot reload during development when viewing a page with a GoogleMaps instance
   * Not very useful
   */
  "Performance warning! LoadScript has been reloaded unintentionally! ",
];

export const suppressWarnings = () => {
  const originalWarn = console.warn;

  console.warn = (...args) => {
    if (
      _WARNINGS.map((warning) => {
        return args[0].toString().includes(warning);
      })
    ) {
      return;
    }
    originalWarn.apply(console, [...args]);
  };
};
