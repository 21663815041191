import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProfileSettingsDrawer from "./ProfileSettingsDrawer";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

// third-party
import { FormattedMessage } from "react-intl";

// project imports
import MainCard from "components/Cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import { useAuth } from "hooks/contexts/AuthContext";

// assets
import { IconLogout, IconSettings, IconUser } from "@tabler/icons-react";
import useConfig from "hooks/useConfig";
import { PublicOutlined } from "@mui/icons-material";
import { getAvatar, setViewState } from "store/slices/user";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { locus, activeUser } = useSelector((state) => state.user);

  // const [sdm, setSdm] = useState(true);
  // const [value, setValue] = useState("");
  // const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const toggleDrawer = (val) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(val);
  };

  // const handleProfileClick = async (event) => {
  //   console.log("handleProfileClick");
  //   handleClose(event);
  // };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleListItemClick = async (event, index, route = "") => {
    await dispatch(setViewState("locus"));
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      await navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        className="guided-tour-user-profile"
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.secondary.main
              : theme.palette.primary.light,
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.background.paper
              : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background:
              theme.palette.mode === "dark"
                ? `${theme.palette.secondary.main}!important`
                : `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={getAvatar(activeUser?.avatar)}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
              p: 0.6,
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
            alt={activeUser?.username}
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="24px"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Stack sx={{ pb: 2 }}>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">Welcome,</Typography>
                          <Typography
                            component="span"
                            variant="h4"
                            sx={{ fontWeight: 400 }}
                          >
                            {activeUser?.username}
                          </Typography>
                        </Stack>
                        {/*<Typography variant="subtitle2">Project Admin</Typography>*/}
                      </Stack>
                      {/*<OutlinedInput*/}
                      {/*    sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}*/}
                      {/*    id="input-search-profile"*/}
                      {/*    value={value}*/}
                      {/*    onChange={(e) => setValue(e.target.value)}*/}
                      {/*    placeholder="Search profile options"*/}
                      {/*    startAdornment={*/}
                      {/*        <InputAdornment position="start">*/}
                      {/*            <IconSearch stroke={1.5} size="16px" color={theme.palette.grey[500]} />*/}
                      {/*        </InputAdornment>*/}
                      {/*    }*/}
                      {/*    aria-describedby="search-helper-text"*/}
                      {/*    inputProps={{*/}
                      {/*        'aria-label': 'weight'*/}
                      {/*    }}*/}
                      {/*/>*/}
                      <Divider />
                    </Box>
                    <Box sx={{ p: 2, pt: 0 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={toggleDrawer(true)}
                        >
                          <ListItemIcon>
                            <IconUser stroke={1.5} size="20px" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                <FormattedMessage id="profile" />
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        {locus && !!activeUser?.groups?.length && (
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 1}
                            onClick={(event) =>
                              handleListItemClick(event, 1, "/locus/clients")
                            }
                          >
                            <ListItemIcon>
                              <PublicOutlined stroke={1.5} size="20px" />
                            </ListItemIcon>

                            <ListItemText
                              primary={
                                <Grid
                                  container
                                  spacing={1}
                                  justifyContent="space-between"
                                >
                                  <Grid item>
                                    <Typography variant="body2">
                                      <FormattedMessage id="locus-global" />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton>
                        )}
                        <ListItemButton
                          sx={{ borderRadius: `${borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="20px" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                <FormattedMessage id="logout" />
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
      <ProfileSettingsDrawer open={drawerOpen} setOpen={setDrawerOpen} />
    </>
  );
};

export default ProfileSection;
