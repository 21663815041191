import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { Box, Divider, Typography } from "@mui/material";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { gridSpacing } from "store/constant";
import { IconTallymark1 } from "@tabler/icons-react";
import { LocusHomeIcon } from "assets/icons/LocusIcons.js";
import { SitePopover } from "./SitePopover";
import { useSelector } from "store";
import { linkSX } from "./Breadcrumb.helpers";
import { DashboardPopover } from "./DashboardPopover";
import { activeRoutes } from "../../routes";
import { getSitesAvailableToUser } from "../../routes/ProtectedRoute";
import { useLocusRouter } from "../../hooks/useLocusRouter";
import testLogoDark from "../../assets/images/Test-Logo_Dark.svg";
import testLogoLight from "../../assets/images/Test-Logo_Light.svg";
import { storage } from "../../utils/helpers/storage";

export const Breadcrumbs = ({
  card,
  divider,
  icon,
  icons,
  maxItems,
  separator,
}) => {
  const theme = useTheme();
  const { selectSite, getHomeRoute } = useLocusRouter();
  const { selectedClient, selectedSite } = useSelector(
    (state) => state.customer,
  );
  const { viewState, activeUser } = useSelector((state) => state.user);
  const { kioskMode, kioskHideBranding } = useSelector((state) => state.menu);
  const { getBreadcrumbRoute } = useLocusRouter();

  const iconStyle = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: "24px",
    height: "24px",
    color: theme.palette.primary.main,
  };

  const [search, setSearch] = useState("");

  const location = useLocation().pathname.split("/");
  location.shift();

  const sites = getSitesAvailableToUser({
    sites: selectedClient?.sites,
    groups: activeUser?.groups,
  });

  const filterSites = !!search
    ? sites?.filter((site) =>
        site.friendlyName.toLowerCase().includes(search?.toLowerCase() || ""),
      )
    : sites;

  const handleSearchSites = async (event) => {
    setSearch(event?.target?.value || event);
  };

  // item separator
  const SeparatorIcon = separator;
  const separatorIcon = separator ? (
    <SeparatorIcon stroke={1.5} size="16px" />
  ) : (
    <IconTallymark1 stroke={1.5} size="16px" />
  );

  let ItemIcon;

  const showClient = viewState === "client" || viewState === "site";
  const showDashboardSelector =
    viewState === "site" &&
    (location.includes("dashboards") || location.includes("reports"));

  let getRoute = (path, routes) => {
    return routes?.filter((route) => {
      return route.path === path;
    })[0];
  };
  const locationMap = useMemo(() => {
    let parentPath =
      location[0] === "client" ? `/client/:clientId` : `/${location[0]}`;
    let rootRoute = getRoute(parentPath, activeRoutes);

    let _locationMap = [];
    // handle global overview breadcrumb for '/locus' since global overview shouldn't be prefixed to every locus child route
    if (location.length === 1 && location[0] === "locus") {
      _locationMap.push(getRoute("", rootRoute?.children));
    }
    location.reduce((acc, path) => {
      if (path === "site") {
        path = "site/:siteId";
      }

      let childNav = getRoute(path, acc?.children);
      if (childNav) {
        _locationMap.push(childNav);
        acc = childNav;
      }
      return acc;
    }, rootRoute);
    return _locationMap;
  }, [location, activeRoutes]);

  const mainContent = useMemo(() => {
    let fullRoute = "";
    // eslint-disable-next-line array-callback-return
    return locationMap?.map((route) => {
      // Hold reference to parent
      fullRoute += route.path + "/";
      // ItemIcon = navItem?.icon ? navItem.icon : AccountTreeTwoToneIcon;
      if (route?.breadcrumb?.display) {
        return (
          <Typography
            key={route.path}
            component={Link}
            to={getBreadcrumbRoute(fullRoute)}
            // to={getNavItemURL({ location, navItem, url: navItem?.url })}
            variant="subtitle1"
            sx={linkSX(theme)}
          >
            {icons && (
              <ItemIcon
                style={iconStyle}
                sx={{ color: theme.palette.primary.main }}
              />
            )}
            {route.breadcrumb?.display}
          </Typography>
        );
      }
    });
  }, [locationMap]);

  return (
    <>
      <Box>
        <MuiBreadcrumbs
          sx={{
            "& .MuiBreadcrumbs-separator": { width: 16, ml: 1.25, mr: 1.25 },
          }}
          aria-label="breadcrumb"
          maxItems={maxItems || 8}
          separator={separatorIcon}
        >
          {!!activeUser?.groups?.length && (
            <Typography
              component={Link}
              to={getHomeRoute()}
              color="inherit"
              variant="subtitle1"
              sx={linkSX(theme)}
              aria-label="home"
            >
              {icons && (
                <LocusHomeIcon
                  sx={{ color: theme.palette.primary.main }}
                  // sx={{ ...iconStyle, color: theme.palette.primary.main }}
                />
              )}
              {showClient && !kioskHideBranding ? (
                <Box
                  style={{
                    height: "inherit",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    display: "flex",
                  }}
                >
                  <img
                    src={
                      activeUser.theme === "dark"
                        ? selectedClient?.darkLogoUrl || testLogoDark
                        : selectedClient?.lightLogoUrl || testLogoLight
                    }
                    alt="Logo"
                    height="auto"
                    style={{
                      maxHeight: "100%",
                      maxWidth: "150px",
                      objectFit: "contain",
                      height: "40px",
                      width: "auto",
                    }}
                  />
                </Box>
              ) : (
                <LocusHomeIcon
                  sx={{ mr: 0, color: theme.palette.primary.main }}
                  // sx={{ ...iconStyle, mr: 0, color: theme.palette.primary.main }}
                />
              )}
              {!icon && "Dashboard"}
            </Typography>
          )}
          {showClient && !kioskMode && !kioskHideBranding && (
            <SitePopover
              handleSearchSites={handleSearchSites}
              handleSelectSite={selectSite}
              filterSites={filterSites}
            />
          )}
          {showClient && kioskMode && !kioskHideBranding && (
            <Typography color="inherit" variant="body2" sx={linkSX(theme)}>
              {selectedSite?.name}
            </Typography>
          )}

          {mainContent}

          {showDashboardSelector && (
            <DashboardPopover kioskMode={kioskMode}></DashboardPopover>
          )}

          {kioskMode && storage.getKioskName() && (
            <Typography color="inherit" variant="body2" sx={linkSX(theme)}>
              {storage.getKioskName()}
            </Typography>
          )}
        </MuiBreadcrumbs>
      </Box>
      {card === false && divider !== false && (
        <Divider
          sx={{ borderColor: theme.palette.primary.main, mb: gridSpacing }}
        />
      )}
    </>
  );
};

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  divider: PropTypes.bool,
  icon: PropTypes.bool,
  icons: PropTypes.bool,
  maxItems: PropTypes.number,
  navigation: PropTypes.object,
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
