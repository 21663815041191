import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusCMSMessagesIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6 0H2.4C1.08 0 0.012 1.08 0.012 2.4L0 24L4.8 19.2H21.6C22.92 19.2 24 18.12 24 16.8V2.4C24 1.08 22.92 0 21.6 0ZM18 14.4H6C5.34 14.4 4.8 13.86 4.8 13.2C4.8 12.54 5.34 12 6 12H18C18.66 12 19.2 12.54 19.2 13.2C19.2 13.86 18.66 14.4 18 14.4ZM18 10.8H6C5.34 10.8 4.8 10.26 4.8 9.6C4.8 8.94 5.34 8.4 6 8.4H18C18.66 8.4 19.2 8.94 19.2 9.6C19.2 10.26 18.66 10.8 18 10.8ZM18 7.2H6C5.34 7.2 4.8 6.66 4.8 6C4.8 5.34 5.34 4.8 6 4.8H18C18.66 4.8 19.2 5.34 19.2 6C19.2 6.66 18.66 7.2 18 7.2Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
