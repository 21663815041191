// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import { dispatch } from "../index";

import albert from "../../assets/images/users/Albert.svg";
import baseball from "../../assets/images/users/Baseball.svg";
import basketball from "../../assets/images/users/Basketball.svg";
import bernice from "../../assets/images/users/Bernice.svg";
import bird from "../../assets/images/users/Bird.svg";
import cat from "../../assets/images/users/Cat.svg";
import dog from "../../assets/images/users/Dog.svg";
import flower from "../../assets/images/users/Flower.svg";
import football from "../../assets/images/users/Football.svg";
import golf from "../../assets/images/users/Golf.svg";
import harriet from "../../assets/images/users/Harriet.svg";
import horse from "../../assets/images/users/Horse.svg";
import lizard from "../../assets/images/users/Lizard.svg";
import moon from "../../assets/images/users/Moon.svg";
import penelope from "../../assets/images/users/Penelope.svg";
import rainbow from "../../assets/images/users/Rainbow.svg";
import soccer from "../../assets/images/users/Soccer.svg";
import walter from "../../assets/images/users/Walter.svg";

import { countries, languages, getEmojiFlag } from "countries-list";
import { storage } from "../../utils/helpers/storage";
import { jwtDecode } from "jwt-decode";

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  users: { rows: [], count: 0 },
  selectedUser: {
    groups: [],
    email: "",
    username: "",
    locale: "",
    timezone: "",
    avatar: "",
    clientRole: "Associate",
  },
  activeUser:
    storage.getActiveUser() !== "undefined" && storage.getActiveUser()
      ? JSON.parse(storage.getActiveUser())
      : {},
  viewState: storage.getViewState() || "", // 'locus', 'client', 'site',
  locus: storage.getLocus() || false,
  warehouseRoles: [
    "Supervisor",
    "Solution Designer",
    "Operations Manager",
    "Executive",
    "General Manager",
    "Super User",
    "Locus",
  ],
  localeMap: [],
  domain: storage.getDomain() || null,
  ssoIdpUrl: storage.getSSOIdpUrl() || null,
  ssoIdpClient: storage.getSSOIdpClient() || null,
  ssoUser: storage.getSSOUser() || false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getUsersSuccess(state, action) {
      state.users = action.payload;
    },

    // SET SELECTED User
    getSelectedUserSuccess(state, action) {
      state.selectedUser = action.payload;
    },

    // SET SELECTED CUSTOMER
    setActiveUser(state, action) {
      storage.setActiveUser(JSON.stringify(action.payload));
      state.activeUser = action.payload;
    },

    // SET SELECTED CUSTOMER
    setViewState(state, action) {
      storage.setViewState(action.payload);
      state.viewState = action.payload;
    },

    // SET SELECTED CUSTOMER
    setLocus(state, action) {
      storage.setLocus(action.payload);
      state.locus = action.payload;
    },

    // SET SELECTED CUSTOMER
    setDomain(state, action) {
      let domain =
        action?.payload?.replace(/[^a-zA-Z0-9\s-]/g, "")?.trim() ||
        action?.payload;
      storage.setDomain(domain);
      axios.defaults.headers["locus-domain"] = domain;
      state.domain = domain;
    },

    // SET SELECTED CUSTOMER
    setSsoIdpUrl(state, action) {
      storage.setSSOIdpUrl(action.payload);
      state.ssoIdpUrl = action.payload;
    },

    // SET SELECTED CUSTOMER
    setSsoIdpClient(state, action) {
      storage.setSSOIdpClient(action.payload);
      state.ssoIdpClient = action.payload;
    },

    // SET setLocaleMap
    setLocaleMap(state, action) {
      state.localeMap = action.payload;
    },

    setSsoUser(state, action) {
      state.ssoUser = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber || 1,
        pageSize: pageSize || 25,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }

      params = { ...params, ...searchFilter };

      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/user`,
        {
          params,
        },
      );
      if (response?.data?.data) {
        response.data.rows = response?.data?.data;
      }
      if (response?.data?.totalCount) {
        response.data.count = response?.data?.totalCount;
      }
      dispatch(
        slice.actions.getUsersSuccess(
          response?.data || {
            rows: [],
            count: 0,
          },
        ),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllUsers() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/user`,
      );
      if (response?.data?.data) {
        response.data.rows = response?.data?.data;
      }
      if (response?.data?.totalCount) {
        response.data.count = response?.data?.totalCount;
      }
      dispatch(
        slice.actions.getUsersSuccess(
          response?.data || {
            rows: [],
            count: 0,
          },
        ),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUser(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/user/${id}`,
      );
      dispatch(slice.actions.getSelectedUserSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.error("Error fetching user:", error);
      // Rethrow the error so it can be caught by the caller
      throw error;
    }
  };
}

export function createUser(data, selectedClient) {
  return async () => {
    try {
      if (!data.phone) {
        delete data.phone;
      }
      if(data.ssoUser){
        delete data.username;
      }
      delete data.ssoUser;
      data.domain = selectedClient?.domain
        ? selectedClient?.domain
        : storage.getDomain() || null;
      data.enabled = true;
      if (data?.avatar?.length <= 0) {
        delete data["avatar"];
      }
      return await axios.post(
        `${process.env.REACT_APP_AUTH_API}/user`,
        data,
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function deleteUser(data) {
  return async () => {
    try {
      return await axios.delete(
        `${process.env.REACT_APP_AUTH_API}/user/${data}`,
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

//
export function editUser(userId, data, useLoginDomain) {
  return async () => {
    try {
      let headers = {};
      if (useLoginDomain) {
        const decoded = storage.getAccessToken()
          ? jwtDecode(storage.getAccessToken())
          : null;
        headers = {
          headers: {
            "locus-region": storage.getUserRegion()
              ? storage.getUserRegion()
              : storage.getRegion(),
            "locus-domain": decoded?.domain,
          },
        };
      }
      return await axios.put(
        `${process.env.REACT_APP_AUTH_API}/user/${userId}`,
        data,
        headers,
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function expireUserPassword(username) {
  return async () => {
    try {
      return await axios.post(
        `${process.env.REACT_APP_AUTH_API}/password/expire?username=${username}`,
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function resendWelcomeEmail(username) {
  return async () => {
    try {
      return await axios.patch(
        `${process.env.REACT_APP_AUTH_API}/email/welcome`,
        {
          username: username,
        },
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function resendEmailVerification(username) {
  return async () => {
    try {
      return await axios.patch(
        `${process.env.REACT_APP_AUTH_API}/email/verify`,
        {
          username: username,
        },
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function resendPhoneVerification(username) {
  return async () => {
    try {
      return await axios.patch(
        `${process.env.REACT_APP_AUTH_API}/phone/verify`,
        {
          username: username,
        },
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getAvatar(selection) {
  let avatar = null;
  switch (selection) {
    case "albert":
      avatar = albert;
      break;
    case "baseball":
      avatar = baseball;
      break;
    case "basketball":
      avatar = basketball;
      break;
    case "bernice":
      avatar = bernice;
      break;
    case "bird":
      avatar = bird;
      break;
    case "dog":
      avatar = dog;
      break;
    case "cat":
      avatar = cat;
      break;
    case "flower":
      avatar = flower;
      break;
    case "football":
      avatar = football;
      break;
    case "golf":
      avatar = golf;
      break;
    case "harriet":
      avatar = harriet;
      break;
    case "horse":
      avatar = horse;
      break;
    case "lizard":
      avatar = lizard;
      break;
    case "moon":
      avatar = moon;
      break;
    case "penelope":
      avatar = penelope;
      break;
    case "rainbow":
      avatar = rainbow;
      break;
    case "soccer":
      avatar = soccer;
      break;
    case "walter":
      avatar = walter;
      break;
    default:
    // nothing
  }
  return avatar;
}

export function setActiveUser(user) {
  return async () => {
    try {
      dispatch(slice.actions.setActiveUser(user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedUser(user) {
  return async () => {
    try {
      dispatch(slice.actions.getSelectedUserSuccess(user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setViewState(user) {
  return async () => {
    try {
      dispatch(slice.actions.setViewState(user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setLocus(bool) {
  return async () => {
    try {
      dispatch(slice.actions.setLocus(bool));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setDomain(domain) {
  return async () => {
    try {
      dispatch(slice.actions.setDomain(domain));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSsoIdpUrl(ssoIdpUrl) {
  return async () => {
    try {
      dispatch(slice.actions.setSsoIdpUrl(ssoIdpUrl));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSsoIdpClient(ssoIdpClient) {
  return async () => {
    try {
      dispatch(slice.actions.setSsoIdpClient(ssoIdpClient));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSsoUser(bool) {
  return async () => {
    try {
      dispatch(slice.actions.setSsoUser(bool));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLocaleMap() {
  let map = Object.keys(countries).map(function (country) {
    return countries[country].languages.map((language) => {
      return {
        label: `${getEmojiFlag(country)} ${languages[language].name} (${countries[country].name})`,
        locale: `${language.toLowerCase()}_${country.toLowerCase()}`,
        language: `${languages[language].name}`,
        country: `${countries[country].name}`,
        countryCode: country,
        languageCode: language,
      };
    });
  });
  return async () => {
    try {
      dispatch(slice.actions.setLocaleMap(map.flat()));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePassword(data, useLoginDomain) {
  return async () => {
    let headers = {};
    if (useLoginDomain) {
      const decoded = storage.getAccessToken()
        ? jwtDecode(storage.getAccessToken())
        : null;
      headers = {
        headers: {
          "locus-region": storage.getUserRegion()
            ? storage.getUserRegion()
            : storage.getRegion(),
          "locus-domain": decoded?.domain,
        },
      };
    }
    try {
      return await axios.put(
        `${process.env.REACT_APP_AUTH_API}/password`,
        data,
        headers,
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export const avatars = {
  albert: albert,
  baseball: baseball,
  basketball: basketball,
  bernice: bernice,
  bird: bird,
  dog: dog,
  cat: cat,
  flower: flower,
  football: football,
  golf: golf,
  harriet: harriet,
  horse: horse,
  lizard: lizard,
  moon: moon,
  penelope: penelope,
  rainbow: rainbow,
  soccer: soccer,
  walter: walter,
};
