import { SUPER_ADMIN } from "../constants/user";

export const isSuperAdmin = (activeUser) => {
  return (
    activeUser.groups?.filter((group) => {
      return group.name === SUPER_ADMIN;
    }).length > 0
  );
};

export const hasPermissions = (permissions, activeUser) => {
  return activeUser?.groups?.some((item) => permissions.includes(item?.name));
};

export const filterDashboardsBySection = (analyticsSection) => {
  return (row) => {
    return analyticsSection === "dashboards" || analyticsSection === "dashboard"
      ? row.type === "dashboard"
      : row.type !== "dashboard";
  };
};

/**
 * Filter dashboards that have sites assigned by the selected site
 * Dashboards that do not have sites assigned are allowed
 */
export const filterDashboardsBySite = (selectedSite) => {
  return (row) => {
    if (row?.sites?.length < 1) return true;
    return row.sites.filter((site) => site.id === selectedSite.id).length > 0;
  };
};

export const sortDashboardsByName = () => {
  return (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0);
};

export const getDashboardsByAccess = (data, analyticsSection, selectedSite) => {
  if (!data?.rows) return [];
  // filter reports to what the user has access to
  // type: "dashboard", "report", "paginated",
  // filter reports by site access
  return data?.rows
    .filter(filterDashboardsBySection(analyticsSection))
    .filter(filterDashboardsBySite(selectedSite))
    .sort(sortDashboardsByName());
};
