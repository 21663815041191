import { ProtectedElement } from "../ProtectedRoute";
import { Loadable } from "../../components/Loadable/Loadable";
import { lazy } from "react";
import { LOCUS_TOOLS_USER, SUPER_ADMIN } from "../../utils/constants/user";
import componentLoader from "../../utils/helpers/componentLoader";

const UpdateConfigurationFiles = Loadable(
  lazy(() => componentLoader(() => import("views/tools/site-actions/update-configuration-files"))),
);
const SiteConfigView = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/site-actions/update-configuration-files/view")),
  ),
);
const DeviceConfiguration = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/tools/device-configuration/DeviceConfiguration"),
    ),
  ),
);
const IntegrationMonitoring = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/IntegrationMonitoring")),
  ),
);
const SiteActions = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/site-actions/SiteActions")),
  ),
);
const ActionsOverview = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/site-actions/ActionsOverview")),
  ),
);

const ClearCart = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/site-actions/actions/ClearCart")),
  ),
);

const FleetAdmin = Loadable(
  lazy(() => componentLoader(() => import("views/tools/FleetAdmin"))),
);

export const ToolsRoutes = {
  path: "tools",
  children: [
    // {
    //   path: "site-configuration",
    //   element: (
    //     <ProtectedElement
    //       element={SiteConfiguration}
    //       allowedGroups={[LOCUS_TOOLS_USER]}
    //     />
    //   ),
    //   breadcrumb: {
    //     display: "Site Configuration",
    //   },
    // },
    {
      path: "site-actions",
      breadcrumb: {
        display: "Site Actions",
      },
      children: [
        {
          path: "",
          element: (
            <ProtectedElement
              element={SiteActions}
              allowedGroups={[SUPER_ADMIN, LOCUS_TOOLS_USER]}
            />
          ),
        },
        {
          path: "actions-overview",
          breadcrumb: {
            display: "Actions Overview",
          },
          children: [
            {
              path: "",
              element: (
                <ProtectedElement
                  element={ActionsOverview}
                  allowedGroups={[SUPER_ADMIN, LOCUS_TOOLS_USER]}
                />
              ),
            },
            {
              path: "clear-cart",
              element: (
                <ProtectedElement
                  element={ClearCart}
                  allowedGroups={[SUPER_ADMIN, LOCUS_TOOLS_USER]}
                />
              ),
              breadcrumb: {
                display: "Clear Cart",
              },
            },
          ],
        },
        {
          path: "update-configuration-files",
          element: (
            <ProtectedElement
              element={UpdateConfigurationFiles}
              allowedGroups={[LOCUS_TOOLS_USER]}
            />
          ),
          breadcrumb: {
            display: "Update Configuration Files",
          },
        },
        {
          path: "update-configuration-files/view/:floorId",
          element: (
            <ProtectedElement
              element={SiteConfigView}
              allowedGroups={[LOCUS_TOOLS_USER]}
            />
          ),
        },
      ],
    },
    {
      path: "device-configuration",
      element: (
        <ProtectedElement
          element={DeviceConfiguration}
          allowedGroups={[LOCUS_TOOLS_USER]}
        />
      ),
      breadcrumb: {
        display: "Device Configuration",
      },
    },
    {
      path: "device-configuration/:clientId",
      element: (
        <ProtectedElement
          element={DeviceConfiguration}
          allowedGroups={[LOCUS_TOOLS_USER]}
        />
      ),
      breadcrumb: {
        display: "Device Configuration",
      },
    },
    {
      path: "integration-monitoring",
      element: (
        <ProtectedElement
          element={IntegrationMonitoring}
          allowedGroups={[LOCUS_TOOLS_USER]}
        />
      ),
      breadcrumb: {
        display: "Integration Monitoring",
      },
    },
    {
      path: "fleet-admin",
      breadcrumb: {
        display: "Fleet Admin",
      },
      element: <FleetAdmin />,
    },
  ],
};
