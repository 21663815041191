import { lazy } from "react";
import { MinimalLayout } from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import { Loadable } from "components/Loadable/Loadable";
import componentLoader from "../utils/helpers/componentLoader";

const AuthLogin = Loadable(
  lazy(() => componentLoader(() => import("views/authentication/Login3"))),
);
const AuthRegister = Loadable(
  lazy(() => componentLoader(() => import("views/authentication/Register3"))),
);
const AuthForgotPassword = Loadable(
  lazy(() =>
    componentLoader(() => import("views/authentication/ForgotPassword3")),
  ),
);
const ResetPassword = Loadable(
  lazy(() =>
    componentLoader(() => import("views/authentication/ResetPassword3")),
  ),
);
const VerifyPhone = Loadable(
  lazy(() => componentLoader(() => import("views/authentication/VerifyPhone"))),
);
const VerifyEmail = Loadable(
  lazy(() => componentLoader(() => import("views/authentication/VerifyEmail"))),
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <MinimalLayout />
    </NavMotion>
  ),
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/register",
      element: <AuthRegister />,
    },
    {
      path: "/forgot-password",
      element: <AuthForgotPassword />,
    },
    {
      path: "/change-password",
      element: <ResetPassword />,
    },
    {
      path: "/login/sso",
      element: <AuthLogin />,
    },
    {
      path: "/verify-phone",
      element: <VerifyPhone />,
    },
    {
      path: "/verify-email",
      element: <VerifyEmail />,
    },
  ],
};

export default LoginRoutes;
