export const toggleKioskQueryParam = (isKioskMode, navigate) => {
  const searchParams = new URLSearchParams(window.location.search);

  if (isKioskMode) {
    searchParams.set("kiosk", "true");
  } else {
    searchParams.delete("kiosk");
  }

  const newUrl = {
    pathname: window.location.pathname,
    search: searchParams.toString(),
  };

  navigate(newUrl, { replace: true });
};

export const mobileCheck = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};
