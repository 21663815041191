import { countries } from "countries-list";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const routeIdToDisplayText = (string) => {
  return string.split("-").map(capitalizeFirstLetter).join(" ");
};

export const CheckAccess = (permissions, activeUser) => {
  return activeUser?.groups?.some((item) => permissions.includes(item?.name));
};

export const formatAddress = (selectedClient) => {
  if (!selectedClient || !selectedClient.address) {
    return ""; // Return empty string if no address
  }

  const { line1, line2, city, state, postalCode, countryCode } =
    selectedClient.address;
  const parts = [line1, line2].filter(Boolean); // Include line1 and line2 if they exist
  const cityStateCode = [city, state, postalCode].filter(Boolean).join(" "); // Join city, state, and postalCode with a space, not a comma

  parts.push(cityStateCode); // Add the combined city, state, postalCode string to parts
  if (countryCode) {
    parts.push(countryCode); // Add countryCode to the parts if it exists
  }

  return parts.filter(Boolean).join(", "); // Join all parts with a comma and space, ensuring no 'undefined' values are included
};

export const getAddressParts = (address) => {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK") {
        const parsedAddress = {
          loc: {
            coordinates: [
              results[0].geometry.location.lat(),
              results[0].geometry.location.lng(),
            ],
            type: "Point",
          },
          line1: "",
          city: "",
          countryCode: "",
          countryShortName: "",
          continentCode: "",
          timezone: "",
        };

        const findComponentByType = (type) =>
          results[0].address_components.find((component) =>
            component.types.includes(type),
          );

        const streetNumber = findComponentByType("street_number");
        const route = findComponentByType("route");
        if (streetNumber || route) {
          parsedAddress.line1 = [streetNumber?.long_name, route?.long_name]
            .filter(Boolean)
            .join(" ");
        }

        const cityComponent =
          findComponentByType("locality") ||
          findComponentByType("postal_town") ||
          findComponentByType("administrative_area_level_2");
        if (cityComponent) {
          parsedAddress.city = cityComponent.long_name;
        }

        const stateComponent = findComponentByType(
          "administrative_area_level_1",
        );
        if (stateComponent && stateComponent.short_name.length <= 3) {
          parsedAddress.state = stateComponent.short_name;
        }

        const postalCodeComponent = findComponentByType("postal_code");
        if (postalCodeComponent) {
          parsedAddress.postalCode = postalCodeComponent.short_name;
        }

        const countryComponent = findComponentByType("country");
        if (countryComponent) {
          parsedAddress.countryCode = countryComponent.short_name;
          parsedAddress.countryShortName = countryComponent.long_name;
          // Assuming 'countries' is a predefined object mapping country codes to continent codes
          parsedAddress.continentCode =
            countries[countryComponent.short_name.toUpperCase()]?.continent;
        }
        resolve(parsedAddress);
      } else {
        reject(
          "Geocode was not successful for the following reason: " + status,
        );
      }
    });
  });
};

export const getAutocompleteService = () => ({
  current: window?.google
    ? new window.google.maps.places.AutocompleteService()
    : null,
});

export const getTimezoneByAddress = (addressObject) => {
  return new Promise((resolve, reject) => {
    if (!addressObject || !addressObject.place_id) {
      reject("No valid place ID provided.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: addressObject.place_id }, (results, status) => {
      if (status === "OK") {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();

        // Assume apiKey is already available via environment variables or configuration
        const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude}%2C${longitude}&timestamp=${Math.floor(
          Date.now() / 1000,
        )}&key=AIzaSyBC6RIBWSsnHvpwn2hj3io1hRC_zAAA99g`;
        // Fetch the timezone data
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "OK") {
              resolve({
                timeZoneId: data.timeZoneId,
                timeZoneName: data.timeZoneName,
              });
            } else {
              reject(
                "Timezone fetch was not successful for the following reason: " +
                  data.status,
              );
            }
          })
          .catch((error) => {
            reject(
              "Network error while fetching timezone information: " +
                error.message,
            );
          });
      } else {
        reject(
          "Geocode was not successful for the following reason: " + status,
        );
      }
    });
  });
};

export function toRoundedInteger(value) {
  if (value === null || value === undefined || isNaN(value)) {
    return 0;
  }

  return Math.round(Number(value));
}
