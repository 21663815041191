import { SUPER_ADMIN } from "../constants/user";

export function filterStandardGroups(groups, sites) {
  sites = sites?.map((site) => {
    return {
      id: site?.id,
      status: site?.status,
    };
  });

  let standardGroups = groups?.filter((group) => {
    return group?.canModify;
  });
  const filteredStandardGroups = standardGroups?.filter((group) => {
    let site = sites?.find((site) => site?.id === group?.site);
    return site?.status ? site.status !== "Decommissioned" : false;
  });
  return filteredStandardGroups || [];
  // return standardGroups.filter((group) => {
  //   let site = sites.find((site) => site?.id === group?.site);
  //   return site?.status !== "Decommissioned";
  // });
}

export function filterAdvancedGroups(groups, viewState, userGroups) {
  return groups?.filter((group) => {
    if (viewState === "locus") {
      let hasSuperAdmin = userGroups?.some((g) => g["name"] === SUPER_ADMIN);
      if (hasSuperAdmin) {
        return group?.locus;
      } else {
        return group?.locus && group?.name !== SUPER_ADMIN;
      }
    }
    return !group?.locus;
  });
}
