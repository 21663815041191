import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusKnowledgeBaseIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.6 22.8C2.94 22.8 2.3748 22.5648 1.9044 22.0944C1.434 21.624 1.1992 21.0592 1.2 20.4V3.6C1.2 2.94 1.4352 2.3748 1.9056 1.9044C2.376 1.434 2.9408 1.1992 3.6 1.2H20.4C21.06 1.2 21.6252 1.4352 22.0956 1.9056C22.566 2.376 22.8008 2.9408 22.8 3.6V20.4C22.8 21.06 22.5648 21.6252 22.0944 22.0956C21.624 22.566 21.0592 22.8008 20.4 22.8H3.6ZM12 19.2C12.42 19.2 12.7752 19.0548 13.0656 18.7644C13.356 18.474 13.5008 18.1192 13.5 17.7C13.5 17.28 13.3548 16.9248 13.0644 16.6344C12.774 16.344 12.4192 16.1992 12 16.2C11.58 16.2 11.2248 16.3452 10.9344 16.6356C10.644 16.926 10.4992 17.2808 10.5 17.7C10.5 18.12 10.6452 18.4752 10.9356 18.7656C11.226 19.056 11.5808 19.2008 12 19.2ZM13.2 13.56C13.26 13.24 13.3652 12.96 13.5156 12.72C13.666 12.48 13.9608 12.14 14.4 11.7C15.14 10.96 15.64 10.36 15.9 9.9C16.16 9.44 16.29 8.92 16.29 8.34C16.29 7.32 15.9148 6.4752 15.1644 5.8056C14.414 5.136 13.4692 4.8008 12.33 4.8C11.47 4.8 10.7048 4.9752 10.0344 5.3256C9.364 5.676 8.8496 6.1408 8.4912 6.72C8.3112 7 8.2612 7.2952 8.3412 7.6056C8.4212 7.916 8.6012 8.1308 8.8812 8.25C9.1612 8.37 9.4364 8.3752 9.7068 8.2656C9.9772 8.156 10.192 7.9708 10.3512 7.71C10.5512 7.43 10.8212 7.2152 11.1612 7.0656C11.5012 6.916 11.8512 6.8408 12.2112 6.84C12.7712 6.84 13.2212 6.99 13.5612 7.29C13.9012 7.59 14.0712 7.97 14.0712 8.43C14.0712 8.81 13.9564 9.19 13.7268 9.57C13.4972 9.95 13.142 10.35 12.6612 10.77C12.1412 11.23 11.7412 11.7 11.4612 12.18C11.1812 12.66 11.0412 13.11 11.0412 13.53C11.0412 13.81 11.1464 14.05 11.3568 14.25C11.5672 14.45 11.822 14.55 12.1212 14.55C12.4012 14.55 12.6364 14.4552 12.8268 14.2656C13.0172 14.076 13.1416 13.8408 13.2 13.56Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
