import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusFleetAdminIcon = ({ color, fontSize }) => {
  return (
    <SvgIcon
      color={color ? color : ""}
      fontSize={fontSize ? fontSize : "medium"}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 21.9969L0.396923 0H1.99385V1.2H5.99077C5.99077 0.996923 6.07385 0.563077 6.38769 0.396923C6.78462 0.193846 6.98769 0.6 7.19077 0.996923C7.29231 1.2 7.79077 2.09538 8.28923 3C8.78769 3.89538 9.28615 4.8 9.38769 5.00308C9.59077 5.4 8.78769 6 8.39077 5.4C8.06769 4.92 7.19077 3.20308 6.79385 2.4H2.00308C2.13231 5.52923 2.48308 11.9169 2.80615 12.3969C2.87077 12.4985 2.93538 12.5908 2.98154 12.6738C3.22154 13.0615 3.30462 13.2 3.80308 13.2H4.48615L4.30154 13.3385C3.68308 13.7908 3.52615 14.6215 3.90462 15.2677L4.97538 17.0585C5.35385 17.7046 6.15692 17.9538 6.84 17.6769L7.53231 17.3908L7.62462 18.0923C7.71692 18.8492 8.37231 19.3846 9.10154 19.3846H10.8092V21.6C10.8092 21.9969 9.81231 23.0031 8.20615 22.8C8.20615 21.3969 7.50462 19.8 5.40923 19.8C3.60923 19.8 2.61231 21.6 2.80615 22.8C1.40308 23.1969 0.00923077 21.9969 0.00923077 21.9969H0ZM5.60308 24C6.48923 24 7.2 23.28 7.2 22.4031C7.2 21.5262 6.48 20.8062 5.60308 20.8062C4.72615 20.8062 4.00615 21.5262 4.00615 22.4031C4.00615 23.28 4.72615 24 5.60308 24Z"
          fill="currentColor"
        />
        <path
          d="M14.8708 12.6092C14.8708 12.48 14.8708 12.36 14.8523 12.2308L15.9138 11.4462C16.1446 11.28 16.2 10.9662 16.0615 10.7262L15 8.93539C14.9354 8.81539 14.8246 8.73231 14.6861 8.68615C14.5569 8.64 14.4092 8.64923 14.28 8.70462L13.0523 9.21231C12.84 9.06462 12.6184 8.94462 12.3877 8.83385L12.2215 7.55077C12.1846 7.27385 11.9446 7.06154 11.6584 7.06154H9.53537C9.24921 7.06154 8.99998 7.27385 8.96306 7.55077L8.79691 8.83385C8.56614 8.93538 8.3446 9.06462 8.13229 9.21231L6.9046 8.70462C6.64614 8.59385 6.33229 8.69539 6.19383 8.93539L5.13229 10.7262C4.99383 10.9662 5.04921 11.2708 5.27998 11.4462L6.34152 12.2308C6.31383 12.48 6.31383 12.7292 6.34152 12.9785L5.27998 13.7631C5.04921 13.9292 4.99383 14.2431 5.13229 14.4831L6.19383 16.2738C6.33229 16.5138 6.64614 16.6154 6.9046 16.5046L8.13229 15.9969C8.3446 16.1446 8.56614 16.2646 8.79691 16.3754L8.96306 17.6585C8.99998 17.9354 9.23998 18.1477 9.52614 18.1477H11.6492C11.9354 18.1477 12.1754 17.9354 12.2123 17.6585L12.3784 16.3754C12.6092 16.2738 12.8308 16.1446 13.0431 15.9969L14.2708 16.5046C14.5292 16.6154 14.8431 16.5138 14.9815 16.2738L16.0431 14.4831C16.1815 14.2431 16.1261 13.9385 15.8954 13.7631L14.8338 12.9785C14.8523 12.8492 14.8523 12.7292 14.8523 12.6L14.8708 12.6092ZM10.6246 14.5477C9.52614 14.5477 8.63075 13.68 8.63075 12.6092C8.63075 11.5385 9.52614 10.6708 10.6246 10.6708C11.7231 10.6708 12.6184 11.5385 12.6184 12.6092C12.6184 13.68 11.7231 14.5477 10.6246 14.5477Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
