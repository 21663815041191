import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusSSOLoginIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7C9.51998 7 7.5 9.01495 7.5 11.5C7.5 13.3597 8.63593 14.9608 10.25 15.6453V16.79L8.79503 18.25L10.545 20L9.74997 20.79V22.0151L11.7699 24L13.75 22.0151V15.6454C15.364 14.9608 16.5 13.3597 16.5 11.5C16.5 9.01495 14.48 7 12 7ZM12 10.9985C11.4477 10.9985 11 10.5508 11 9.99853C11 9.44619 11.4477 8.99855 12 8.99855C12.5523 8.99855 13 9.44619 13 9.99853C13 10.5508 12.5523 10.9985 12 10.9985Z"
          fill="currentColor"
        />
        <path
          d="M20.595 7.71505C20.81 7.02493 20.895 6.30998 20.855 5.57497C20.675 2.52495 18.09 0.0249336 15.09 0H15.045C12.62 0 10.46 1.55502 9.61 3.815C8.72001 3.07501 7.57499 2.72 6.41002 2.83507C4.745 3.00001 3.30002 4.16503 2.72 5.80501C2.46 6.53499 2.395 7.28503 2.52997 8.04C0.999985 8.79502 0 10.39 0 12.14C0 14.665 1.89501 16.5 4.49998 16.5H9.12712L9.24996 16.3768V16.262C7.56006 15.2864 6.50001 13.4827 6.50001 11.4998C6.50001 8.46712 8.96732 5.99981 12 5.99981C15.0327 5.99981 17.5 8.46712 17.5 11.4998C17.5 13.4828 16.44 15.2865 14.75 16.2621V16.5001H19.5C22.105 16.5001 24 14.6651 24 12.14C24 10 22.575 8.20001 20.595 7.71505Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
