import { Outlet } from "react-router-dom";

// ==============================|| MINIMAL LAYOUT ||============================== //

export const MinimalLayout = () => (
  <>
    <Outlet />
    {/* <Customization /> */}
  </>
);
