import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusDeviceConfigurationIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="28"
        viewBox="0 0 24 28"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.00001 5.6H18V6.8C18 7.46 18.54 8 19.2 8C19.86 8 20.4 7.46 20.4 6.8V3.2C20.4 1.88 19.32 0.811999 18 0.811999L6.00001 0.799999C4.68001 0.799999 3.60001 1.88 3.60001 3.2V6.8C3.60001 7.46 4.14001 8 4.80001 8C5.46001 8 6.00001 7.46 6.00001 6.8V5.6ZM16.944 18.656L20.748 14.852C20.8593 14.741 20.9475 14.6091 21.0077 14.4639C21.0679 14.3188 21.0989 14.1632 21.0989 14.006C21.0989 13.8488 21.0679 13.6932 21.0077 13.548C20.9475 13.4029 20.8593 13.271 20.748 13.16L16.944 9.356C16.476 8.888 15.708 8.888 15.24 9.356C15.1288 9.46702 15.0405 9.59888 14.9803 9.74405C14.9201 9.88922 14.8891 10.0448 14.8891 10.202C14.8891 10.3592 14.9201 10.5148 14.9803 10.6599C15.0405 10.8051 15.1288 10.937 15.24 11.048L18.204 14L15.24 16.964C15.1288 17.075 15.0405 17.2069 14.9803 17.352C14.9201 17.4972 14.8891 17.6528 14.8891 17.81C14.8891 17.9672 14.9201 18.1228 14.9803 18.2679C15.0405 18.4131 15.1288 18.545 15.24 18.656C15.708 19.124 16.476 19.124 16.944 18.656ZM8.74801 16.952L5.79601 14L8.74801 11.048C8.85925 10.937 8.94751 10.8051 9.00773 10.6599C9.06795 10.5148 9.09894 10.3592 9.09894 10.202C9.09894 10.0448 9.06795 9.88922 9.00773 9.74405C8.94751 9.59888 8.85925 9.46702 8.74801 9.356C8.28001 8.888 7.51201 8.888 7.04401 9.356L3.24001 13.16C3.12876 13.271 3.04051 13.4029 2.98029 13.548C2.92007 13.6932 2.88908 13.8488 2.88908 14.006C2.88908 14.1632 2.92007 14.3188 2.98029 14.4639C3.04051 14.6091 3.12876 14.741 3.24001 14.852L7.04401 18.656C7.51201 19.124 8.28001 19.124 8.74801 18.656C9.22801 18.188 9.21601 17.42 8.74801 16.952ZM18 22.4H6.00001V21.2C6.00001 20.54 5.46001 20 4.80001 20C4.14001 20 3.60001 20.54 3.60001 21.2V24.8C3.60001 26.12 4.68001 27.2 6.00001 27.2H18C19.32 27.2 20.4 26.12 20.4 24.8V21.2C20.4 20.54 19.86 20 19.2 20C18.54 20 18 20.54 18 21.2V22.4Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
