import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  Switch,
  FormControlLabel,
  FormControl,
  Link,
  Box,
  Stack,
  Autocomplete,
  Typography,
} from "@mui/material";
import { openSnackbar } from "store/slices/snackbar";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { editUser, getLocaleMap, setActiveUser } from "store/slices/user";
import useConfig from "../../../../hooks/useConfig";
import AvatarSelection from "./AvatarSelection"; // Adjust the import path
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { storage } from "../../../../utils/helpers/storage";

const personalDetailsSchema = yup.object({
  username: yup
    .string()
    .min(2, "Username should be of minimum 2 characters length")
    .matches(/^[a-z0-9\s]+$/, "Only Lowercase Letters and Numbers")
    .required("Username is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup.string().test("phone-valid", "+44 20 7123 1234", (value) => {
    return value ? matchIsValidTel(value) : true;
  }),
  theme: yup.string(),
  locale: yup.string().required("Language is required"),
  defaultSite: yup.string(),
});

const PersonalDetailsForm = ({ setOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { localeMap, activeUser, viewState } = useSelector(
    (state) => state.user,
  );
  const { kioskMode } = useSelector((state) => state.menu);

  const { onChangeMenuType } = useConfig();
  const [isTourRunning, setIsTourRunning] = React.useState(false);

  const handleResetToursAndCloseDrawer = () => {
    setIsTourRunning(true);
    const tours = {
      completedAnalyticsTour: false,
      completedMainTour: false,
    };
    dispatch(
      setActiveUser({
        ...activeUser,
        tours: tours,
      }),
    );
    setOpen(false);
  };

  const shouldShowTourButton = React.useMemo(() => {
    return (
      activeUser &&
      !kioskMode &&
      viewState !== "locus" &&
      isDesktop &&
      !storage.getLocus()
    );
  }, [activeUser, kioskMode, viewState, isDesktop]);

  React.useEffect(() => {
    dispatch(getLocaleMap());
  }, []);

  return (
    <Formik
      initialValues={{
        username: activeUser?.username,
        email: activeUser?.email,
        phone: activeUser?.phone,
        theme: activeUser?.theme,
        locale: activeUser?.locale,
        defaultSite: activeUser?.defaultSite,
        avatar: activeUser?.avatar,
      }}
      validationSchema={personalDetailsSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (isTourRunning) return;
        let updateUser = await dispatch(editUser(activeUser.id, values, true));
        if (updateUser && updateUser?.status === 200) {
          setSubmitting(false);
          dispatch(
            openSnackbar({
              open: true,
              message: "Personal Details Updated",
              variant: "alert",
              alert: {
                color: "success",
              },
              close: false,
            }),
          );
          dispatch(setActiveUser({ ...activeUser, ...values }));
        } else {
          setStatus({ success: false });
          dispatch(
            openSnackbar({
              open: true,
              message: "Error Updating Personal Details Updated",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: true,
              transition: "Fade",
            }),
          );
          setSubmitting(false);
        }
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <Stack spacing={2}>
            <AvatarSelection
              avatar={values.avatar}
              setAvatar={(newAvatar) => setFieldValue("avatar", newAvatar)}
            />
            {!activeUser?.ssoSubject && (
              <Field
                as={TextField}
                name="username"
                label="Username"
                helperText={touched.username ? errors.username : ""}
                error={touched.username && Boolean(errors.username)}
                fullWidth
              />
            )}
            {!activeUser?.ssoSubject && (
              <Field
                as={TextField}
                name="email"
                label="Email"
                helperText={touched.email ? errors.email : ""}
                error={touched.email && Boolean(errors.email)}
                fullWidth
              />
            )}
            <div>
              <Typography variant="h6" display="block" mb={1}>
                Phone Number (optional)
              </Typography>
              <MuiTelInput
                id="phone"
                name="phone"
                fullWidth
                value={values.phone}
                onChange={(value) => {
                  setFieldValue("phone", value);
                }}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
              />
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={values.theme === "dark"}
                  onChange={() => {
                    const newTheme = values.theme === "dark" ? "light" : "dark";
                    onChangeMenuType(newTheme);
                    setFieldValue("theme", newTheme);
                  }}
                  name="theme"
                />
              }
              label={values.theme === "dark" ? "Dark Mode" : "Light Mode"}
            />
            <FormControl fullWidth>
              <Autocomplete
                sx={{ marginTop: 1 }}
                disablePortal
                disableClearable
                id="locale"
                options={localeMap}
                value={
                  localeMap?.find(
                    (option) => option.locale === values.locale,
                  ) || null
                }
                fullWidth
                isOptionEqualToValue={(option, value) => {
                  return (
                    value === undefined ||
                    value === "" ||
                    option === value ||
                    option.locale === values.locale ||
                    option.label === value
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    name="locale"
                    label="Language"
                    error={touched.locale && Boolean(errors.locale)}
                    helperText={
                      touched.locale && errors.locale && errors.locale
                    }
                  />
                )}
                onChange={(event, newValue) => {
                  setFieldValue("locale", newValue.locale);
                }}
              />
            </FormControl>
            {/* <FormControl fullWidth>
              <InputLabel id="defaultSite-label">Default Site</InputLabel>
              <Field
                as={Select}
                name="defaultSite"
                labelId="defaultSite-label"
                label="Default Site"
                value={values.defaultSite}
                onChange={(e) => setFieldValue("defaultSite", e.target.value)}
              ></Field>
            </FormControl> */}

            <Box sx={{ mt: "auto" }}>
              {shouldShowTourButton && (
                <Link
                  component="button"
                  variant="body2"
                  onClick={handleResetToursAndCloseDrawer}
                  sx={{
                    cursor: "pointer",
                    my: 2,
                    textAlign: "left",
                    textDecoration: "none",
                  }}
                >
                  Restart Guided Tour
                </Link>
              )}
              {!!activeUser?.groups?.length && (
                <Button type="submit" fullWidth variant="contained">
                  Save
                </Button>
              )}
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalDetailsForm;
