// action - account reducer
import { createAction } from "@reduxjs/toolkit";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";
export const onSetSelectedClient = createAction(SET_SELECTED_CLIENT);
export const SET_SELECTED_SITE = "SET_SELECTED_SITE";
export const onSetSelectedSite = createAction(SET_SELECTED_SITE);
