export const getFilteredResultsByAppliedFilters = (filterBy) => {
  return (row) => {
    if (filterBy.type === "bool") {
      return filterBy.operator === "="
        ? Object.prototype.hasOwnProperty.call(row, filterBy.property)
          ? row[filterBy.property] === filterBy.value
          : row === filterBy.value
        : Object.prototype.hasOwnProperty.call(row, filterBy.property)
          ? row[filterBy.property] !== filterBy.value
          : row !== filterBy.value;
    }
    if (filterBy.type === "string") {
      return filterBy.operator === "="
        ? Object.prototype.hasOwnProperty.call(row, filterBy.property)
          ? row[filterBy.property] === filterBy.value
          : row === filterBy.value
        : Object.prototype.hasOwnProperty.call(row, filterBy.property)
          ? row[filterBy.property] !== filterBy.value
          : row !== filterBy.value;
    }
    return true;
  };
};

export const getFilteredResultsByFilterName = (filterName, rows, filters) => {
  const _filters = filters.find((x) => x.name === filterName);
  const _filterBy = _filters?.filterBy;
  if (!_filters) return [];
  if (!_filterBy || _filterBy.length === 0) return rows;
  let response = [];
  _filterBy.reduce(
    (acc, filterBy) => {
      if (filterBy.operator === "=") {
        response.push(
          ...rows.filter(getFilteredResultsByAppliedFilters(filterBy)),
        );
      } else {
        if (response.length === 0) response = [...rows];
        response = response.filter(
          getFilteredResultsByAppliedFilters(filterBy),
        );
      }
    },
    [...rows],
  );
  return [...new Set(response)];
};
