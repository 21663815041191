import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Button, Stack } from "@mui/material";
import { openSnackbar } from "store/slices/snackbar";
import { useDispatch, useSelector } from "store";
import useScriptRef from "hooks/useScriptRef";

import { updatePassword } from "store/slices/user";

// Validation Schema
const passwordChangeSchema = yup.object({
  currentPassword: yup.string().required("Current password is required"),
  newPassword: yup
    .string()
    .min(6, "Password should be at least 6 characters long")
    .required("New password is required"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirming new password is required"),
});

export const PasswordChangeForm = () => {
  const dispatch = useDispatch();
  const scriptedRef = useScriptRef();
  const { activeUser } = useSelector((state) => state.user);

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      validationSchema={passwordChangeSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        let updateUser = await dispatch(
          updatePassword(
            {
              username: activeUser.username,
              currentPassword: values.currentPassword,
              newPassword: values.newPassword,
            },
            true
          )
        );
        if (updateUser && updateUser?.status === 200) {
          setSubmitting(false);
          dispatch(
            openSnackbar({
              open: true,
              message: "Password Updated",
              variant: "alert",
              alert: {
                color: "success",
              },
              close: false,
            })
          );
        } else {
          setStatus({ success: false });
          dispatch(
            openSnackbar({
              open: true,
              message: "Error updating password",
              variant: "alert",
              alert: {
                color: "error",
              },
              close: true,
              transition: "Fade",
            })
          );
          setSubmitting(false);
        }
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <Stack spacing={2}>
            {" "}
            {/* Add this Stack component with spacing */}
            <Field
              as={TextField}
              type="password"
              name="currentPassword"
              label="Current Password"
              helperText={touched.currentPassword ? errors.currentPassword : ""}
              error={touched.currentPassword && Boolean(errors.currentPassword)}
              fullWidth
            />
            <Field
              as={TextField}
              type="password"
              name="newPassword"
              label="New Password"
              helperText={touched.newPassword ? errors.newPassword : ""}
              error={touched.newPassword && Boolean(errors.newPassword)}
              fullWidth
            />
            <Field
              as={TextField}
              type="password"
              name="confirmNewPassword"
              label="Confirm New Password"
              helperText={
                touched.confirmNewPassword ? errors.confirmNewPassword : ""
              }
              error={
                touched.confirmNewPassword && Boolean(errors.confirmNewPassword)
              }
              fullWidth
            />
            <Button type="submit" fullWidth variant="contained">
              Change Password
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordChangeForm;
