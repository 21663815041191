import React, { useMemo, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
  Stack,
  IconButton,
} from "@mui/material";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";

import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Header } from "./Header/Header";
import HorizontalBar from "./HorizontalBar";
import { Sidebar } from "./Sidebar/Sidebar";
import navigation from "menu-items";
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import { drawerWidth } from "store/constant";
import { openDrawer } from "store/slices/menu";
import { useDispatch, useSelector } from "store";
import { setKioskMode } from "store/slices/menu";
import { toggleKioskQueryParam, mobileCheck } from "../../utils/kiosk-utils";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { getHelpUrl } from "../../utils/constants/help-urls";

// assets
import { IconChevronRight } from "@tabler/icons-react";
import { AnalyticsDisruptionBanner } from "../../components/Banner/AnalyticsDisruptionBanner";

// styles
const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, layout, kiosk }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter + 200,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft:
        layout === LAYOUT_CONST.VERTICAL_LAYOUT && !kiosk
          ? -(drawerWidth - 72)
          : "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      marginTop:
        layout === "horizontal"
          ? "calc(135px + env(safe-area-inset-top))"
          : "calc(88px + env(safe-area-inset-top))",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginTop: "calc(88px + env(safe-area-inset-top))",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "22.5px 5px 5px 5px",
      marginRight: "10px",
      marginTop: "calc(55px + env(safe-area-inset-top))",
    },
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter + 200,
    }),
    marginLeft: layout === "horizontal" ? "20px" : 0,
    marginTop:
      layout === "horizontal"
        ? "calc(135px + env(safe-area-inset-top))"
        : "calc(88px + env(safe-area-inset-top))",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.up("md")]: {
      marginTop:
        layout === "horizontal"
          ? "calc(135px + env(safe-area-inset-top))"
          : "calc(88px + env(safe-area-inset-top))",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      padding: "16px",
      marginTop: "calc(88px + env(safe-area-inset-top))",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      padding: "16px",
      marginTop: "calc(88px + env(safe-area-inset-top))",
    },
  }),
}));

// ==============================|| MAIN LAYOUT ||============================== //

export const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { drawerOpen, kioskMode, globalBanner } = useSelector(
    (state) => state.menu,
  );
  const { container, drawerType, layout } = useConfig();
  const [showKioskButton, setShowKioskButton] = useState(false);
  const [kioskActive, setKioskActive] = useState(false);
  const { selectedDashboard } = useSelector((state) => state.analytics);
  const { activeUser } = useSelector((state) => state.user);

  const openHelpWindow = () => {
    window.open(
      getHelpUrl("", selectedDashboard?.name),
      "_blank",
      "noopener,noreferrer",
    );
  };

  const setDrawerState = () => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has("kiosk")) {
      setKioskActive(true);
      dispatch(setKioskMode(true));
      dispatch(openDrawer(false));
    } else {
      dispatch(setKioskMode(false));
      if (!matchDownMd && kioskActive) {
        dispatch(openDrawer(true));
      } else if (kioskActive) {
        dispatch(openDrawer(false));
      }
      setKioskActive(false);
    }
  };

  useEffect(() => {
    setDrawerState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerType, matchDownMd]);

  useEffect(() => {
    // Check if selectedDashboard is of type 'dashboard' or if the current route includes 'overview-dashboard'
    const isDashboard =
      selectedDashboard?.type === "dashboard" &&
      location.pathname.toLowerCase().includes("dashboards");
    const isOverviewDashboard =
      location.pathname.includes("overview-dashboard");
    const isMobile = mobileCheck();
    const isAndroidTablet =
      /Android/i.test(navigator.userAgent) &&
      !/Mobile/i.test(navigator.userAgent);
    setShowKioskButton(
      (isDashboard || isOverviewDashboard) && !isMobile && !isAndroidTablet,
    );
    setDrawerState();
  }, [location, navigate, selectedDashboard]);

  const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

  const header = useMemo(
    () => (
      <Toolbar sx={{ p: condition ? "10px" : "16px" }}>
        <Header />
      </Toolbar>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layout, matchDownMd],
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* header */}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          bgcolor: "background.default",
          transition: drawerOpen ? theme.transitions.create("width") : "none",
        }}
        style={{
          paddingTop: "env(safe-area-inset-top)",
        }}
      >
        {header}
      </AppBar>

      {/* horizontal menu-list bar */}
      {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT &&
        !matchDownMd &&
        !!activeUser?.groups?.length && <HorizontalBar />}

      {/* drawer */}
      {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) &&
        !!activeUser?.groups?.length &&
        !kioskMode && <Sidebar />}

      {/* main content */}
      <Main
        theme={theme}
        open={drawerOpen}
        layout={layout}
        kiosk={kioskMode ? "true" : undefined}
      >
        <Container
          maxWidth={container ? "lg" : false}
          {...(!container && { sx: { px: { xs: 0 } } })}
        >
          {!kioskMode && (
            <>
              {globalBanner && (
                <AnalyticsDisruptionBanner></AnalyticsDisruptionBanner>
              )}
              <Stack direction="row" alignItems="center" spacing={1} pb={1}>
                <Breadcrumbs
                  separator={IconChevronRight}
                  navigation={navigation}
                  icon
                />
                <Box sx={{ border: "none" }} style={{ marginLeft: "auto" }}>
                  {showKioskButton && (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        dispatch(setKioskMode(true));
                        toggleKioskQueryParam(true, navigate)
                      }}
                    >
                      <ConnectedTvIcon />
                    </IconButton>
                  )}
                  <IconButton
                    color="primary"
                    className="guided-tour-help-icon"
                    onClick={() => openHelpWindow()}
                  >
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </Box>
              </Stack>
            </>
          )}
          <Outlet />
        </Container>
      </Main>
    </Box>
  );
};
