import { createSlice } from "@reduxjs/toolkit";
import axios from "axios"; // this does not include app headers
import { dispatch } from "../index";
import axiosServices from "../../utils/axios";

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  releases: [],
  healthCheckToken: null,
  healthCheckPaths: [],
  serviceNames: {
    auth: "Authorization Service",
    activity: "Activity Service",
    analytics: "Analytics Service",
    config: "Configuration Service",
    notifications: "Notification Service",
    services: "General Service",
    tooling: "Tooling Service",
  },
};

const slice = createSlice({
  name: "help",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getReleaseInfoSuccess(state, action) {
      state.releases = action.payload;
    },
    getHealthCheckTokenSuccess(state, action) {
      state.healthCheckToken = action.payload;
    },
    getHealthCheckPathsSuccess(state, action) {
      state.healthCheckPaths = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReleaseInfo(majorVersion) {
  return async () => {
    try {
      const response = await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/release/find?majorVersion=${majorVersion}`,
        {}
      );
      dispatch(slice.actions.getReleaseInfoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getServiceHealthToken() {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/activity/activity/service/health`,
        {},
        {}
      );
      dispatch(slice.actions.getHealthCheckTokenSuccess(response.data.token));
      dispatch(
        slice.actions.getHealthCheckPathsSuccess(response.data.services)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getServiceHealth(token, service) {
  return async () => {
    try {
      return await axios.get(
        `https://management.azure.com/${service.path}/providers/Microsoft.ResourceHealth/availabilityStatuses/current?api-version=2022-10-01`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
