import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

// styles
const LoaderWrapper = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1301,
  width: "100%",
});

// ==============================|| LOADER ||============================== //

export const Loader = ({ show = false }) => {
  // Use useSelector at the beginning of the component function
  const { loading } = useSelector((state) => state.utils);

  // Now return your component JSX
  return (
    <LoaderWrapper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show || loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </LoaderWrapper>
  );
};
