// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import { dispatch } from "../index";
import { storage } from "../../utils/helpers/storage";
import { SUPER_ADMIN } from "../../utils/constants/user";
// ----------------------------------------------------------------------

const defaultAdvancedLocus = [
  {
    id: "636bf1338483ff4453b13496",
    canModify: false,
    name: "Locus Tenant",
    description: "Allows a Locus Robotics User default read access.",
    icon: "visibility_outlined",
    locus: true,
  },
  {
    id: "636bf1338483ff4453b1348c",
    canModify: false,
    name: "Locus Tools User",
    description: "Allows a Locus Robotics user to access all tools.",
    locus: true,
    icon: "build_circle_outlined",
  },
  // HUB-1429
  // {
  //   id: "636bf1338483ff4453b1348a",
  //   canModify: false,
  //   name: "Locus Support Liaison",
  //   description:
  //     "Allows a Locus Robotics user to add, edit, close and respond to support tickets from all clients.",
  //   icon: "support_outlined",
  //   locus: true,
  // },
  {
    id: "636bf1338483ff4453b13494",
    canModify: false,
    name: "Locus Asset Manager",
    description:
      "Allows a Locus Robotics user to view and edit asset information for all clients.",
    locus: true,
    icon: "devices_outlined",
  },
  {
    id: "636bf1338483ff4453b1348b",
    canModify: false,
    name: "Locus Configuration Admin",
    description: "Allows a Locus Robotics user to set-up clients and sites.",
    locus: true,
    icon: "stars_outlined",
  },
  {
    id: "636bf1338483ff4453b1348d",
    canModify: false,
    name: "Locus Customer User Manager",
    description:
      "Allows a Locus Robotics user to view and edit user permissions of client users.",
    icon: "supervised_user_circle_outlined",
    locus: true,
  },
  {
    id: "636bf1338483ff4453b1348f",
    canModify: false,
    name: "Locus User Manager",
    description:
      "Allows a Locus Robotics user to view and edit user permissions of other Locus Robotics users.",
    icon: "supervised_user_circle_outlined",
    locus: true,
  },
  {
    id: "636bf1338483ff4453b1348e",
    canModify: false,
    name: SUPER_ADMIN,
    description: "Allows a Locus Robotics user to have all access.",
    icon: "lock_person_outlined",
    locus: true,
  },
];
const defaultAdvancedClient = [
  {
    id: "636bf1338483ff4453b13490",
    canModify: false,
    name: "Cross-Site Viewer",
    description:
      "Allows a user to view all unprotected information about any site within the client.",
    icon: "preview_outlined",
    preventDisable: ["Configuration Admin"],
  },
  {
    id: "636bf1338483ff4453b13491",
    canModify: false,
    name: "User Manager",
    description:
      "Allows a user to view and edit user profiles across sites in a client.",
    icon: "supervised_user_circle_outlined",
  },
  {
    id: "636bf1338483ff4453b13493",
    canModify: false,
    name: "Configuration Admin",
    description:
      "Allows a user to change client level details such as address and logo. Users must have Cross-Site Viewer permission before other advanced permissions are given.",
    icon: "stars_outlined",
    requires: ["Cross-Site Viewer"],
  },
  // HUB-1429
  // {
  //   id: "636bf1338483ff4453b13492",
  //   canModify: false,
  //   name: "Support Liaison",
  //   description:
  //     "Allows a user to open and edit support tickets with Locus Robotics.",
  //   icon: "support_outlined",
  // },
];

export const initialState = {
  error: null,
  groups: [],
  usersByActiveGroup: [],
  defaultAdvancedLocus: [...defaultAdvancedLocus],
  defaultAdvancedClient: [...defaultAdvancedClient],
  defaultAdvanced: [...defaultAdvancedLocus, ...defaultAdvancedClient],
  groupById: null,
};

const slice = createSlice({
  name: "group",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getGroupSuccess(state, action) {
      state.groups = action.payload;
    },

    // SET SELECTED Group
    getClientAdvancedSuccess(state, action) {
      state.clientAdvanced = action.payload;
    },

    // SET SELECTED Group
    getLocusAdvancedSuccess(state, action) {
      state.locusAdvanced = action.payload;
    },

    getUsersByActiveGroupSuccess(state, action) {
      state.usersByActiveGroup = action.payload;
    },

    resetUsersByActiveGroupSuccess(state, action) {
      state.usersByActiveGroup = action.payload;
    },

    getGroupByIdSuccess(state, action) {
      state.groupById = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getGroups() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/group`,
        {},
      );
      dispatch(slice.actions.getGroupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getusersByActiveGroup(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/group/${id}/members`,
        {},
      );
      let users = [];
      if (response?.data?.users) {
        users = response?.data?.users.filter((user) => {
          return user.type === "human";
        });
      }
      dispatch(slice.actions.getUsersByActiveGroupSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetUsersByActiveGroup() {
  return async () => {
    dispatch(slice.actions.resetUsersByActiveGroupSuccess());
  };
}

export function getGroupById(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/group/${id}`,
        {},
        {},
      );
      dispatch(slice.actions.getGroupByIdSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createGroup(data, selectedClient) {
  return async () => {
    try {
      data.domain = selectedClient?.domain
        ? selectedClient?.domain
        : storage.getDomain()
          ? storage.getDomain()
          : null;
      return await axios.post(
        `${process.env.REACT_APP_AUTH_API}/group`,
        data,
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function deleteGroup(data) {
  return async () => {
    try {
      return await axios.delete(
        `${process.env.REACT_APP_AUTH_API}/group/${data}`,
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function editGroup(groupId, data) {
  return async () => {
    try {
      return await axios.put(
        `${process.env.REACT_APP_AUTH_API}/group/${groupId}`,
        data,
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function batchEditGroup(groupId, members) {
  return async () => {
    try {
      return await axios.patch(
        `${process.env.REACT_APP_AUTH_API}/group/${groupId}/members`,
        {
          usingUsernames: false,
          members: members,
        },
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
