import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusServiceHealthIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.36 3.60001H20.64C21.828 3.60001 22.8 4.57201 22.8 5.76001V11.16H16.9896L15.132 7.43401C14.7648 6.69961 13.566 6.69961 13.1988 7.43401L9.84 14.1408L8.6412 11.754C8.4684 11.3868 8.0904 11.16 7.68 11.16H1.2V5.76001C1.2 4.57201 2.172 3.60001 3.36 3.60001ZM14.16 10.3392L15.3588 12.726C15.5316 13.0932 15.9096 13.32 16.32 13.32H22.8V18.72C22.8 19.908 21.828 20.88 20.64 20.88H3.36C2.172 20.88 1.2 19.908 1.2 18.72V13.32H7.0104L8.8788 17.046C9.0516 17.4132 9.4296 17.64 9.84 17.64C10.2504 17.64 10.6284 17.4132 10.8012 17.046L14.16 10.3392Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
