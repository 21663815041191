// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import { dispatch } from "../index";
import { onSetSelectedClient } from "../actions";

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  devices: { rows: [], count: 0 },
  selectedDevice: {},
};

const slice = createSlice({
  name: "device",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getDevicesSuccess(state, action) {
      state.devices = action.payload;
    },

    // SET SELECTED Device
    getSelectedDeviceSuccess(state, action) {
      state.selectedDevice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onSetSelectedClient, (state, action) => {
        state.devices = initialState.devices;
      })
      .addDefaultCase((state, action) => {});
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDevices({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedClient,
  selectedSite,
  viewState,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }
      if (searchFilter) {
        // params.filter = searchFilter;
        params.name = searchFilter;
      }
      if (viewState === "client" && selectedClient && selectedClient?.id) {
        params.client = selectedClient.id;
      }
      if (viewState === "site" && selectedSite && selectedSite?.id) {
        params.site = selectedSite.id;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/device`,
        {
          params,
        },
        {},
      );
      dispatch(slice.actions.getDevicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDevice(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/device/${id}`,
        {},
        {},
      );
      dispatch(slice.actions.getSelectedDeviceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/*
*   "name": "string",
  "username": "string",
  "locale": "string",
  "timezone": "string",
  "deviceType": "string",
  "description": "string",
  "deviceKey": "string",
  "serial": "string",
  "client": "string",
  "site": "string",
  "theme": "string",
  "url": "string"
*/
export function createDevice(data) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVICES_API}/services/device`,
        {
          name: data.name,
          client: data.client,
          domain: data.domain,
          deviceType: "kiosk",
          description: data.description,
          deviceKey: data.deviceKey,
          locale: "en_US",
          timezone: "America/New_York",
          serial: "default-serial-number",
          site: data.site,
          theme: data.theme,
          url: data.url,
          hideBranding: data.hideBranding,
        },
        {},
      );
      // console.log("createDevice - response", response);
      dispatch(slice.actions.getSelectedDeviceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
/**
 * @param {string} deviceId
 * */
export function deleteDevice(deviceId) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVICES_API}/services/device/${deviceId}`,
        {},
      );
      // console.log("deleteDevice - response", response);
      dispatch(slice.actions.getSelectedDeviceSuccess(null));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
/**
 * @param {string} deviceId
 * @param {LocusDevice} device
 * */
export function updateDevice({ deviceId, device }) {
  return async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVICES_API}/services/device/${deviceId}`,
        device,
        {},
      );
      // console.log("updateDevice - response", response);
      dispatch(slice.actions.getSelectedDeviceSuccess(null));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
