import { createSlice } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";
import { storage } from "../../utils/helpers/storage";

// initial state
const initialState = {
  selectedItem: ["dashboard"],
  selectedID: null,
  // Todo update width if theme changes for theme.breakpoints.down("md")
  drawerOpen: window.innerWidth < 899.95 ? false : true,
  error: null,
  menu: {},
  kioskHideBranding: storage.getKioskHideBranding() || false,
  kioskMode: false,
  globalBanner: false,
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action) {
      state.selectedItem = action.payload;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // get dashboard menu
    getMenuSuccess(state, action) {
      state.menu = action.payload;
    },

    setKioskMode(state, action) {
      state.kioskMode = action.payload;
    },

    setKioskHideBranding(state, action) {
      state.kioskHideBranding = action.payload;
    },
  },
});

export default menu.reducer;

export const {
  activeItem,
  openDrawer,
  activeID,
  setKioskMode,
  setKioskHideBranding,
} = menu.actions;

export function getMenu() {
  return async () => {
    try {
      // const response = await axios.get('/api/menu/widget');
      // dispatch(menu.actions.getMenuSuccess(response.data.widget));
    } catch (error) {
      dispatch(menu.actions.hasError(error));
    }
  };
}
