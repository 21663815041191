import React, { useState } from "react";
import { Avatar, Box, Button, Grid, Popover, Typography } from "@mui/material";
import { avatars } from "store/slices/user";

const AvatarSelection = ({ avatar, setAvatar }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAvatarChange = (avatarKey) => {
    setAvatar(avatarKey);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "avatar-popover" : undefined;

  return (
    <Box>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          minWidth: "56px",
          color: "#fff",
          minHeight: "56px",
          padding: 0,
          position: "relative",
          mb: 1,
        }}
      >
        {/* Semi-transparent overlay */}
        {isHovered && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(21 131 210 / 85%)", // Light blue background with .85 opacity
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              zIndex: 1000,
              borderRadius: "50%",
              fontWeight: "600",
            }}
          >
            <Box component={"span"} sx={{ color: "#fff" }}>
              CHANGE
            </Box>
          </Box>
        )}
        <Avatar
          src={avatars[avatar]}
          alt="Avatar"
          sx={{
            width: 70,
            height: 70,
            p: 1.5,
            color: "white",
          }}
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography variant="h6" sx={{ p: 1 }}>
          Avatar Selection
        </Typography>
        <Grid container spacing={1} sx={{ p: 1, width: "auto", maxWidth: 250 }}>
          {Object.keys(avatars).map((avatarKey, index) => (
            <Grid
              item
              sx={{
                borderRadius: "50%",
              }}
              xs={4}
              key={avatarKey}
              onClick={() => handleAvatarChange(avatarKey)}
            >
              <Box>
                <Avatar
                  src={avatars[avatarKey]}
                  alt={avatarKey}
                  sx={{
                    width: 66,
                    height: 66,
                    cursor: "pointer",
                    p: 1,
                    background: "#fff",
                    border: "1px solid grey",
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Box>
  );
};

export default AvatarSelection;
