import home from "./home";
import admin from "./admin";
import help from "./help";
import assetManagement from "./asset-management";
import analytics from "./analytics";
import tools from "./tools";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [home, analytics, assetManagement, admin, tools, help],
};

export default menuItems;
