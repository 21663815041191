import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusReleaseInformationIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8364 3.60001H3.16363C2.08363 3.60001 1.2 4.48364 1.2 5.56364V17.3455C1.2 18.4255 2.08363 19.3091 3.16363 19.3091H8.07272V20.2909C8.07272 20.8309 8.51454 21.2727 9.05454 21.2727H14.9455C15.4855 21.2727 15.9273 20.8309 15.9273 20.2909V19.3091H20.8364C21.9164 19.3091 22.8 18.4255 22.8 17.3455V5.56364C22.8 4.48364 21.9164 3.60001 20.8364 3.60001ZM19.8545 17.3455H4.14545C3.60545 17.3455 3.16363 16.9036 3.16363 16.3636V6.54546C3.16363 6.00546 3.60545 5.56364 4.14545 5.56364H19.8545C20.3945 5.56364 20.8364 6.00546 20.8364 6.54546V16.3636C20.8364 16.9036 20.3945 17.3455 19.8545 17.3455ZM17.8909 8.5091H9.05454C8.51454 8.5091 8.07272 8.95092 8.07272 9.49092C8.07272 10.0309 8.51454 10.4727 9.05454 10.4727H17.8909C18.4309 10.4727 18.8727 10.0309 18.8727 9.49092C18.8727 8.95092 18.4309 8.5091 17.8909 8.5091ZM17.8909 12.4364H9.05454C8.51454 12.4364 8.07272 12.8782 8.07272 13.4182C8.07272 13.9582 8.51454 14.4 9.05454 14.4H17.8909C18.4309 14.4 18.8727 13.9582 18.8727 13.4182C18.8727 12.8782 18.4309 12.4364 17.8909 12.4364ZM7.09091 8.5091H5.12727V10.4727H7.09091V8.5091ZM7.09091 12.4364H5.12727V14.4H7.09091V12.4364Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
