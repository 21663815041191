import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusDevicesIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8 20.7273H14.0727C14.2182 20.7273 14.3455 20.6727 14.4545 20.5636C14.5636 20.4545 14.6182 20.3273 14.6182 20.1818C14.6182 20.0364 14.5636 19.9091 14.4545 19.8C14.3455 19.6909 14.2182 19.6364 14.0727 19.6364H10.8C10.6545 19.6364 10.5273 19.6909 10.4182 19.8C10.3091 19.9091 10.2545 20.0364 10.2545 20.1818C10.2545 20.3273 10.3091 20.4545 10.4182 20.5636C10.5273 20.6727 10.6545 20.7273 10.8 20.7273ZM6.98182 24C6.38182 24 5.868 23.7862 5.44037 23.3585C5.01273 22.9309 4.79928 22.4175 4.8 21.8182V2.18182C4.8 1.58182 5.01382 1.068 5.44146 0.640365C5.8691 0.212729 6.38255 -0.00072542 6.98182 1.85214e-06H17.8909C18.4909 1.85214e-06 19.0047 0.21382 19.4324 0.641456C19.86 1.06909 20.0735 1.58255 20.0727 2.18182V21.8182C20.0727 22.4182 19.8589 22.932 19.4313 23.3596C19.0036 23.7873 18.4902 24.0007 17.8909 24H6.98182ZM6.98182 16.3636H17.8909V5.45455H6.98182V16.3636Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
