import { lazy } from "react";
import { useRoutes } from "react-router-dom";
import LoginRoutes from "./LoginRoutes";
import { Loadable } from "components/Loadable/Loadable";
import { ClientRoutes } from "./ClientRoutes";
import { LocusRoutes } from "./LocusRoutes";
import PermissonRoutes from "./PermissionRoutes";
import componentLoader from "../utils/helpers/componentLoader";

const PagesLanding = Loadable(
  lazy(() => componentLoader(() => import("views/authentication/Login3"))),
);
const Kiosk = Loadable(
  lazy(() => componentLoader(() => import("views/kiosk/Kiosk"))),
);
const UrlNotFound = Loadable(
  lazy(() => componentLoader(() => import("views/PageNotFound"))),
);

// ==============================|| ROUTING RENDER ||============================== //

export const activeRoutes = [
  { path: "/", element: <PagesLanding /> },
  { path: "/kiosk", element: <Kiosk /> },
  LoginRoutes,
  LocusRoutes,
  ClientRoutes,
  PermissonRoutes,
  { path: "*", element: <UrlNotFound /> },
];
export default function ThemeRoutes() {
  return useRoutes(activeRoutes);
}
