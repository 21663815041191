import { Suspense } from "react";

// project imports
import { Loader } from "../Loader/Loader";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

export const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
