// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import { dispatch } from "../index";
import { onSetSelectedClient } from "../actions";

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  chargers: { count: 0, rows: [] },
  selectedCharger: {},
};

const slice = createSlice({
  name: "charger",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getChargersSuccess(state, action) {
      state.chargers = action.payload;
    },

    // SET SELECTED Charger
    getSelectedChargerSuccess(state, action) {
      state.selectedCharger = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onSetSelectedClient, (state, action) => {
        state.chargers = initialState.chargers;
      })
      .addDefaultCase((state, action) => {});
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getChargers({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedClient,
  selectedSite,
  viewState,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }
      if (searchFilter) {
        // params.filter = searchFilter;
        params.name = searchFilter;
      }
      if (viewState === "client" && selectedClient && selectedClient?.id) {
        params.client = selectedClient.id;
      }
      if (viewState === "site" && selectedSite && selectedSite?.id) {
        params.site = selectedSite.id;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/charger`,
        { params },
        {}
      );
      dispatch(slice.actions.getChargersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCharger(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/charger/${id}`,
        {},
        {}
      );
      dispatch(slice.actions.getSelectedChargerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createCharger(data) {
  return async () => {
    try {
      console.log("createCharger", data);
      // const response = await axios.post(
      //     `${process.env.REACT_APP_SERVICES_API}/charger`,
      //     {
      //         "chargername": data.chargername,
      //         "email": data.email,
      //         "phone": data.phone || '',
      //         "timezone": data.timezone,
      //         "domain": localStorage.getItem('domain')
      //     },
      //     {}
      // );
      // console.log('createCharger - response', response);
      // dispatch(slice.actions.getSelectedChargerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
