import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
let reactPlugin = new ReactPlugin();
let clickPluginInstance = new ClickAnalyticsPlugin();
let clickPluginConfig = {
  autoCapture: false,
};
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `${process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING}`,
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});
appInsights.loadAppInsights();

const telemetryInitializer = (envelope) => {
  envelope.tags["ai.cloud.domain"] = window.location.hostname;
};
appInsights.addTelemetryInitializer(telemetryInitializer);

export { reactPlugin, appInsights };
