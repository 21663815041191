import * as React from "react";
import { Drawer, Tab, Box } from "@mui/material";
import PasswordChangeForm from "./PasswordChangeForm";
import PersonalDetailsForm from "./PersonalDetailsForm";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector } from "store";
import useConfig from "../../../../hooks/useConfig";

const ProfileSettingsDrawer = ({ open, setOpen }) => {
  const [value, setValue] = React.useState("1");
  const { activeUser } = useSelector((state) => state.user);
  const { onChangeMenuType } = useConfig();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        onChangeMenuType(activeUser?.theme);
        setOpen(false);
      }}
    >
      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} width={350}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Profile Settings" value="1" />
              {!activeUser?.ssoSubject && (
                <Tab label="Change Password" value="2" />
              )}
            </TabList>
          </Box>
          <TabPanel value="1">
            <PersonalDetailsForm setOpen={setOpen} />
          </TabPanel>
          <TabPanel value="2">
            <PasswordChangeForm />
          </TabPanel>
        </TabContext>
      </Box>
    </Drawer>
  );
};

export default ProfileSettingsDrawer;
