// third-party
import { FormattedMessage } from "react-intl";

import {
  LocusCMSMessagesIcon,
  LocusFleetAdminIcon,
  LocusFileUploadIcon,
  LocusDeviceConfigurationIcon,
} from "assets/icons/LocusIcons.js";
import {
  LOCUS_TENANT,
  LOCUS_TOOLS_USER,
  SUPER_ADMIN,
} from "../utils/constants/user";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

// ==============================|| APPLICATION MENU ITEMS ||============================== //
/** @type {LocusMenuGroup} */
const tools = {
  id: "tools",
  title: <FormattedMessage id="tools" />,
  type: "group",
  view: ["site"],
  children: [
    {
      id: "site-actions",
      title: <FormattedMessage id="site-actions" />,
      type: "item",
      icon: CloudSyncIcon,
      url: "tools/site-actions?site=:siteId",
      breadcrumbs: true,
      view: ["site"],
      allowedGroups: [SUPER_ADMIN, LOCUS_TOOLS_USER],
    },
    {
      id: "device-configuration",
      title: <FormattedMessage id="ipad-access" />,
      type: "item",
      icon: LocusDeviceConfigurationIcon,
      url: "tools/device-configuration?site=:siteId",
      breadcrumbs: true,
      view: ["site"],
      allowedGroups: [LOCUS_TOOLS_USER],
    },
    // {
    //   id: "update-configuration-files",
    //   title: <FormattedMessage id="update-configuration-files" />,
    //   type: "item",
    //   icon: LocusFileUploadIcon,
    //   url: "tools/site-actions/update-configuration-files?site=:siteId",
    //   breadcrumbs: true,
    //   view: ["site"],
    //   allowedGroups: [LOCUS_TOOLS_USER],
    // },
    {
      id: "integration-monitoring",
      title: <FormattedMessage id="integration-monitoring" />,
      type: "item",
      icon: LocusCMSMessagesIcon,
      url: "tools/integration-monitoring?site=:siteId",
      breadcrumbs: true,
      view: ["site"],
      allowedGroups: [LOCUS_TOOLS_USER],
    },
    {
      id: "fleet-admin",
      title: <FormattedMessage id="fleet-admin" />,
      type: "item",
      icon: LocusFleetAdminIcon,
      url: "https://prod.fleet.locusbots.io/overview",
      breadcrumbs: false,
      target: true,
      external: true,
      view: ["locus"],
      allowedGroups: [LOCUS_TENANT],
    },
  ],
};

export default tools;
