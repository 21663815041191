import { ProtectedElement } from "../ProtectedRoute";
import {
  LOCUS_CUSTOMER_USER_MANAGER,
  LOCUS_USER_MANAGER,
  USER_MANAGER,
} from "../../utils/constants/user";
import { Loadable } from "../../components/Loadable/Loadable";
import { lazy } from "react";
import componentLoader from "../../utils/helpers/componentLoader";
const Users = Loadable(
  lazy(() => componentLoader(() => import("views/users/UserList"))),
);
const UserOverview = Loadable(
  lazy(() => componentLoader(() => import("views/users/UserOverview"))),
);

export const UsersRoutes = {
  path: "users",
  breadcrumb: {
    display: "Users",
  },
  children: [
    {
      path: "",
      element: (
        <ProtectedElement
          element={Users}
          allowedGroups={[
            LOCUS_USER_MANAGER,
            LOCUS_CUSTOMER_USER_MANAGER,
            USER_MANAGER,
          ]}
        />
      ),
    },
    {
      path: "create",
      breadcrumb: {
        display: "Create",
      },
      element: (
        <ProtectedElement
          element={UserOverview}
          allowedGroups={[
            LOCUS_USER_MANAGER,
            LOCUS_CUSTOMER_USER_MANAGER,
            USER_MANAGER,
          ]}
        />
      ),
    },
    {
      path: ":userId",
      element: (
        <ProtectedElement
          element={UserOverview}
          allowedGroups={[
            LOCUS_USER_MANAGER,
            LOCUS_CUSTOMER_USER_MANAGER,
            USER_MANAGER,
          ]}
        />
      ),
      children: [
        {
          path: "edit",
          element: (
            <ProtectedElement
              element={UserOverview}
              allowedGroups={[
                LOCUS_USER_MANAGER,
                LOCUS_CUSTOMER_USER_MANAGER,
                USER_MANAGER,
              ]}
            />
          ),
        },
      ],
    },
  ],
};
