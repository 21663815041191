import { appInsights } from "../ApplicationInsightsService";

// const LazyComp = lazy(() => componentLoader(() => import("./LazyComponent.js")));
const componentLoader = (lazyComponent, attemptsLeft = 3, interval = 1000) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          appInsights.trackEvent(
            { name: "componentLoader error" },
            {
              error: error,
              lazyComponent: lazyComponent || null,
              attemptsLeft: attemptsLeft || null,
              interval: interval || null,
            },
          );
          if (attemptsLeft === 1) {
            reject(error);
            // Force it to pull from the server and bypass local cache
            window.location.reload(true);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1, interval).then(
            resolve,
            reject,
          );
        }, interval);
      });
  });
};

export default componentLoader;
