import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusUsersIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 18V16.425C0 15.7083 0.366667 15.125 1.1 14.675C1.83333 14.225 2.8 14 4 14C4.21667 14 4.425 14.0043 4.625 14.013C4.825 14.0217 5.01667 14.0423 5.2 14.075C4.96667 14.425 4.79167 14.7917 4.675 15.175C4.55833 15.5583 4.5 15.9583 4.5 16.375V18H0ZM6 18V16.375C6 15.8417 6.146 15.354 6.438 14.912C6.73 14.47 7.14233 14.0827 7.675 13.75C8.20833 13.4167 8.846 13.1667 9.588 13C10.33 12.8333 11.134 12.75 12 12.75C12.8833 12.75 13.696 12.8333 14.438 13C15.18 13.1667 15.8173 13.4167 16.35 13.75C16.8833 14.0833 17.2917 14.471 17.575 14.913C17.8583 15.355 18 15.8423 18 16.375V18H6ZM19.5 18V16.375C19.5 15.9417 19.4457 15.5333 19.337 15.15C19.2283 14.7667 19.066 14.4083 18.85 14.075C19.0333 14.0417 19.221 14.021 19.413 14.013C19.605 14.005 19.8007 14.0007 20 14C21.2 14 22.1667 14.221 22.9 14.663C23.6333 15.105 24 15.6923 24 16.425V18H19.5ZM4 13C3.45 13 2.979 12.804 2.587 12.412C2.195 12.02 1.99933 11.5493 2 11C2 10.4333 2.196 9.95833 2.588 9.575C2.98 9.19167 3.45067 9 4 9C4.56667 9 5.04167 9.19167 5.425 9.575C5.80833 9.95833 6 10.4333 6 11C6 11.55 5.80833 12.021 5.425 12.413C5.04167 12.805 4.56667 13.0007 4 13ZM20 13C19.45 13 18.979 12.804 18.587 12.412C18.195 12.02 17.9993 11.5493 18 11C18 10.4333 18.196 9.95833 18.588 9.575C18.98 9.19167 19.4507 9 20 9C20.5667 9 21.0417 9.19167 21.425 9.575C21.8083 9.95833 22 10.4333 22 11C22 11.55 21.8083 12.021 21.425 12.413C21.0417 12.805 20.5667 13.0007 20 13ZM12 12C11.1667 12 10.4583 11.7083 9.875 11.125C9.29167 10.5417 9 9.83333 9 9C9 8.15 9.29167 7.43733 9.875 6.862C10.4583 6.28667 11.1667 5.99933 12 6C12.85 6 13.5627 6.28767 14.138 6.863C14.7133 7.43833 15.0007 8.15067 15 9C15 9.83333 14.7123 10.5417 14.137 11.125C13.5617 11.7083 12.8493 12 12 12Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
