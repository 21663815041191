import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusChargersIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 19.2L5.49 15.69C5.27 15.47 5.1 15.2152 4.98 14.9256C4.86 14.636 4.8 14.3308 4.8 14.01V8.40002C4.8 7.74002 5.0352 7.17482 5.5056 6.70442C5.976 6.23402 6.5408 5.99922 7.2 6.00002H8.4L7.2 7.20002V2.40002C7.2 2.06002 7.3152 1.77482 7.5456 1.54442C7.776 1.31402 8.0608 1.19922 8.4 1.20002C8.74 1.20002 9.0252 1.31522 9.2556 1.54562C9.486 1.77602 9.6008 2.06082 9.6 2.40002V6.00002H14.4V2.40002C14.4 2.06002 14.5152 1.77482 14.7456 1.54442C14.976 1.31402 15.2608 1.19922 15.6 1.20002C15.94 1.20002 16.2252 1.31522 16.4556 1.54562C16.686 1.77602 16.8008 2.06082 16.8 2.40002V7.20002L15.6 6.00002H16.8C17.46 6.00002 18.0252 6.23522 18.4956 6.70562C18.966 7.17602 19.2008 7.74082 19.2 8.40002V14.01C19.2 14.33 19.14 14.6352 19.02 14.9256C18.9 15.216 18.73 15.4708 18.51 15.69L15 19.2V21.6C15 21.94 14.8848 22.2252 14.6544 22.4556C14.424 22.686 14.1392 22.8008 13.8 22.8H10.2C9.86 22.8 9.5748 22.6848 9.3444 22.4544C9.114 22.224 8.9992 21.9392 9 21.6V19.2Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
