import React, { useState, lazy } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

import Routes from "routes";

import { Locales } from "components/Locales/Locales";
import { NavigationScroll } from "layout/NavigationScroll";
import { RTLLayout } from "layout/RTLLayout";
import { Snackbar } from "components/Snackbar/Snackbar";
import { Loader } from "components/Loader/Loader";
import { LicenseInfo } from "@mui/x-license-pro";

import ThemeCustomization from "themes";

import { JWTProvider as AuthProvider } from "hooks/contexts/JWTContext";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./utils/ApplicationInsightsService";
import RootProvider from "views/tools/site-actions/update-configuration-files/context/RootProvider";
import { UserRoleCheckDialog } from "./components/Dialogs/UserRoleCheckDialog";
import { suppressWarnings } from "./utils/globalOverrides";
import { serviceWorkerInit } from "./LocusServiceWorker";
import { SWReloadPopup } from "./components/Snackbar/SWReloadPopup";

const IntroTourComponent = lazy(() => import("components/IntroTour/IntroTour"));

LicenseInfo.setLicenseKey(
  "e617fdf1397a14f90547461a89f65b55Tz05MzU0MyxFPTE3NTE1MjI2NzQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
);

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleMapsLibraries = ["maps", "geocoding", "places"];

const App = () => {
  suppressWarnings();
  /* https://github.com/JustFly1984/react-google-maps-api/tree/b768d0a528806bb5c40e66fc8564f94afeddff23/packages/react-google-maps-api */
  useJsApiLoader({
    id: "google-maps-provider",
    googleMapsApiKey: apiKey,
    libraries: googleMapsLibraries,
  });

  const [swReloadPopupOpen, setSwReloadPopupOpen] = useState(false);
  const notifyUser = (bool) => {
    setSwReloadPopupOpen(bool);
  };

  serviceWorkerInit(notifyUser);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeCustomization>
        <RTLLayout>
          <Locales>
            <NavigationScroll>
              <RootProvider>
                <AuthProvider>
                  <>
                    <Routes />
                    <Snackbar />
                    <Loader />
                    <UserRoleCheckDialog />
                    <IntroTourComponent />
                    <SWReloadPopup show={swReloadPopupOpen}></SWReloadPopup>
                  </>
                </AuthProvider>
              </RootProvider>
            </NavigationScroll>
          </Locales>
        </RTLLayout>
      </ThemeCustomization>
    </AppInsightsContext.Provider>
  );
};

export default App;
