import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
// import logger from "redux-logger";
import rootReducer from "./reducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // Note: logger must be the last middleware in chain, otherwise it will log thunk and promise, not actual actions
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  // }).concat(logger),   // uncomment to enable redux logging;
});

const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export { store, dispatch, useSelector, useDispatch };
