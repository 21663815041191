import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusOriginBotIcon = ({ color, fontSize }) => {
  return (
    <SvgIcon
      color={color ? color : ""}
      fontSize={fontSize ? fontSize : "medium"}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.2 22L7.6 0H9.2V1.2H13.2C13.2 1 13.28 0.56 13.6 0.4C14 0.2 14.2 0.6 14.4 1C14.5 1.2 15 2.10002 15.5 3.00003C16 3.90002 16.5 4.8 16.6 5C16.8 5.4 16 6 15.6 5.4C15.28 4.92 14.4 3.2 14 2.4H9.2C9.33333 5.53333 9.68 11.92 10 12.4C10.0688 12.5032 10.1258 12.5946 10.176 12.6752C10.4178 13.0629 10.5032 13.2 11 13.2H17.4C17.8 13.2 18 14.2 17.2 14.2C16.56 14.2 13.2 14.3333 11.6 14.4C12.5644 14.775 14.5351 15.251 15.9164 15.5845C16.2539 15.666 16.5562 15.739 16.8 15.8C17.6 16 18 16.8 18 18V21.6C18 22 17 23 15.4 22.8C15.4 21.4 14.7029 19.8 12.6 19.8C10.8 19.8 9.8 21.6 10 22.8C8.6 23.2 7.2 22 7.2 22ZM12.8 24C13.6837 24 14.4 23.2837 14.4 22.4C14.4 21.5163 13.6837 20.8 12.8 20.8C11.9163 20.8 11.2 21.5163 11.2 22.4C11.2 23.2837 11.9163 24 12.8 24Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
