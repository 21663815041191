import { FormattedMessage } from "react-intl";
import {
  LocusClientsIcon,
  LocusUsersIcon,
  LocusPermisionsIcon,
  LocusAnalyticsManagerIcon,
} from "../assets/icons/LocusIcons.js";
import {
  CROSS_SITE_VIEWER,
  LOCUS_CUSTOMER_USER_MANAGER,
  LOCUS_TENANT,
  LOCUS_USER_MANAGER,
  USER_MANAGER,
  VIEWER,
} from "../utils/constants/user";

// ==============================|| APPLICATION MENU ITEMS ||============================== //
/** @type {LocusMenuGroup} */
const admin = {
  id: "admin",
  title: <FormattedMessage id="admin" />,
  type: "group",
  view: ["locus", "client", "site"],
  className: "guided-tour-nav-admin",
  children: [
    {
      id: "clients",
      title: <FormattedMessage id="clients" />,
      type: "item",
      icon: LocusClientsIcon,
      url: "clients",
      breadcrumbs: true,
      view: ["locus"],
      allowedGroups: [LOCUS_TENANT],
    },
    // {
    //   id: "client",
    //   title: <FormattedMessage id="client" />,
    //   type: "item",
    //   icon: LocusClientIcon,
    //   url: "/client/:clientId",
    //   breadcrumbs: true,
    //   view: ["client"],
    //   dynamic: true,
    // },
    // {
    //   id: "site",
    //   title: <FormattedMessage id="site" />,
    //   type: "item",
    //   icon: LocusSiteIcon,
    //   url: "/admin/:clientId/site/:siteId",
    //   breadcrumbs: true,
    //   view: ["client"],
    //   dynamic: true,
    // },
    {
      id: "users",
      title: <FormattedMessage id="users" />,
      type: "item",
      icon: LocusUsersIcon,
      url: "users",
      breadcrumbs: true,
      view: ["locus", "client"],
      className: "guided-tour-nav-admin-users",
      classNameGuidedTour: "guided-tour-nav-admin-users",
      allowedGroups: [
        LOCUS_USER_MANAGER,
        LOCUS_CUSTOMER_USER_MANAGER,
        USER_MANAGER,
      ],
    },
    {
      id: "permissions",
      title: <FormattedMessage id="permissions" />,
      type: "item",
      icon: LocusPermisionsIcon,
      url: "permissions",
      breadcrumbs: true,
      view: ["locus", "client"],
      allowedGroups: [
        LOCUS_USER_MANAGER,
        LOCUS_CUSTOMER_USER_MANAGER,
        USER_MANAGER,
      ],
    },
    // {
    //   id: "client-info",
    //   title: <FormattedMessage id="client-info" />,
    //   type: "item",
    //   icon: LocusPermisionsIcon,
    //   url: "/client/:clientId/info",
    //   breadcrumbs: true,
    //   view: ["client"],
    // },
    {
      id: "site-info",
      title: <FormattedMessage id="site-info" />,
      type: "item",
      icon: LocusPermisionsIcon,
      url: "site-info?site=:siteId",
      breadcrumbs: true,
      view: ["site"],
      allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
    },
    {
      id: "analytics-manager",
      title: <FormattedMessage id="analytics-manager" />,
      type: "item",
      icon: LocusAnalyticsManagerIcon,
      url: "analytics-manager",
      breadcrumbs: true /**/,
      view: ["locus"],
      allowedGroups: [],
    },
  ],
};

export default admin;
