import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusAnalyticsManagerIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.4 1H2.2C1.54 1 1 1.54 1 2.2V9.4C1 10.06 1.54 10.6 2.2 10.6H9.4C10.06 10.6 10.6 10.06 10.6 9.4V2.2C10.6 1.54 10.06 1 9.4 1ZM21.4 1H14.2C13.54 1 13 1.54 13 2.2V9.4C13 10.06 13.54 10.6 14.2 10.6H21.4C22.06 10.6 22.6 10.06 22.6 9.4V2.2C22.6 1.54 22.06 1 21.4 1ZM9.4 13H2.2C1.54 13 1 13.54 1 14.2V21.4C1 22.06 1.54 22.6 2.2 22.6H9.4C10.06 22.6 10.6 22.06 10.6 21.4V14.2C10.6 13.54 10.06 13 9.4 13ZM21.4876 17.6009C21.4975 17.7145 21.5024 17.8231 21.5024 17.9366C21.5024 18.0501 21.4975 18.1588 21.4827 18.2723L22.4009 18.9684C22.5984 19.1214 22.6527 19.3929 22.5293 19.6101L21.6061 21.2046C21.4827 21.4218 21.2161 21.5107 20.989 21.412L19.9277 20.9627C19.745 21.0911 19.5525 21.2046 19.3501 21.2984L19.2069 22.4388C19.1773 22.6856 18.965 22.8732 18.7182 22.8732H16.8769C16.63 22.8732 16.4178 22.6856 16.3881 22.4388L16.245 21.2984C16.0426 21.2046 15.85 21.0911 15.6674 20.9627L14.606 21.412C14.3789 21.5107 14.1124 21.4218 13.9889 21.2046L13.0658 19.6101C12.9424 19.3929 12.9967 19.1164 13.1942 18.9684L14.1124 18.2723C14.0919 18.0506 14.0919 17.8275 14.1124 17.6059L13.1942 16.9098C12.9967 16.7568 12.9424 16.4852 13.0658 16.268L13.9889 14.6686C14.1124 14.4514 14.3789 14.3625 14.606 14.4612L15.6674 14.9105C15.85 14.7821 16.0426 14.6686 16.245 14.5748L16.3881 13.4344C16.4178 13.1876 16.63 13 16.8818 13H18.7231C18.97 13 19.1822 13.1876 19.2119 13.4344L19.355 14.5748C19.5574 14.6686 19.75 14.7821 19.9326 14.9105L20.994 14.4612C21.1043 14.4136 21.2283 14.4085 21.3422 14.4467C21.4561 14.485 21.5519 14.564 21.611 14.6686L22.5342 16.2631C22.6576 16.4803 22.6033 16.7568 22.4058 16.9049L21.4876 17.6009ZM16.0919 17.9366C16.0919 18.8894 16.867 19.6644 17.8197 19.6644C18.7725 19.6644 19.5476 18.8894 19.5476 17.9366C19.5476 16.9838 18.7725 16.2088 17.8197 16.2088C16.867 16.2088 16.0919 16.9838 16.0919 17.9366Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
