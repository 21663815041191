import { ProtectedElement } from "../ProtectedRoute";
import {
  LOCUS_CUSTOMER_USER_MANAGER,
  LOCUS_USER_MANAGER,
  USER_MANAGER,
} from "../../utils/constants/user";
import { Loadable } from "../../components/Loadable/Loadable";
import { lazy } from "react";
import componentLoader from "../../utils/helpers/componentLoader";

const Permissions = Loadable(
  lazy(() => componentLoader(() => import("views/permissions/PermissionList"))),
);
const PermissionOverview = Loadable(
  lazy(() =>
    componentLoader(() => import("views/permissions/PermissionOverview")),
  ),
);

export const PermissionsRoutes = {
  path: "permissions",
  breadcrumb: {
    display: "Permissions",
  },
  children: [
    {
      path: "",
      element: (
        <ProtectedElement
          element={Permissions}
          allowedGroups={[
            LOCUS_USER_MANAGER,
            LOCUS_CUSTOMER_USER_MANAGER,
            USER_MANAGER,
          ]}
        />
      ),
    },
    {
      path: ":id",
      element: (
        <ProtectedElement
          element={PermissionOverview}
          allowedGroups={[
            LOCUS_USER_MANAGER,
            LOCUS_CUSTOMER_USER_MANAGER,
            USER_MANAGER,
          ]}
        />
      ),
    },
  ],
};
