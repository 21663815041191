import { useTheme } from "@mui/material/styles";
import logoDark from "assets/images/locus-dark-logo.svg";
import logo from "assets/images/locus-logo.svg";

const Logo = ({ width }) => {
  const theme = useTheme();
  return (
    <img
      src={theme.palette.mode === "dark" ? logoDark : logo}
      alt="Locus Robotics Logo"
      width={width || "130"}
    />
  );
};
export default Logo;
