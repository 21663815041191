import AuthGuard from "../utils/route-guard/AuthGuard";
import { MainLayout } from "../layout/MainLayout/MainLayout";
import { ProtectedElement } from "./ProtectedRoute";
import { Loadable } from "../components/Loadable/Loadable";
import { lazy } from "react";
import {
  CROSS_SITE_VIEWER,
  VIEWER,
  LOCUS_TENANT,
  SUPER_ADMIN,
  LOCUS_CONFIGURATION_ADMIN,
  CONFIGURATION_ADMIN,
  LOCUS_USER_MANAGER,
  LOCUS_CUSTOMER_USER_MANAGER,
  USER_MANAGER,
} from "../utils/constants/user";
import { AssetManagementRoutes } from "./ChildRoutes/AssetManagementRoutes";
import { PermissionsRoutes } from "./ChildRoutes/PermissionsRoutes";
import { UsersRoutes } from "./ChildRoutes/UsersRoutes";
import { ToolsRoutes } from "./ChildRoutes/ToolsRoutes";
import { HelpRoutes } from "./ChildRoutes/HelpRoutes";
import componentLoader from "../utils/helpers/componentLoader";

const ClientOverview = Loadable(
  lazy(() => componentLoader(() => import("views/client/ClientOverview"))),
);
const SiteCreate = Loadable(
  lazy(() => componentLoader(() => import("views/site/SiteCreate"))),
);
const SiteOverview = Loadable(
  lazy(() => componentLoader(() => import("views/site/SiteOverview"))),
);
const OverviewDashboard = Loadable(
  lazy(() => componentLoader(() => import("views/site/OverviewDashboard"))),
);
const Dashboard = Loadable(
  lazy(() => componentLoader(() => import("views/analytics/Dashboard"))),
);
const Unauthorized = Loadable(
  lazy(() =>
    componentLoader(() => import("components/Unauthorized/Unauthorized")),
  ),
);

export const ClientRoutes = {
  path: "/client/:clientId",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "unauthorized",
      element: <Unauthorized />,
    },
    {
      path: "overview",
      element: (
        <ProtectedElement
          element={ClientOverview}
          allowedGroups={[
            LOCUS_TENANT,
            CROSS_SITE_VIEWER,
            CONFIGURATION_ADMIN,
            LOCUS_USER_MANAGER,
            LOCUS_CUSTOMER_USER_MANAGER,
            USER_MANAGER,
          ]}
        />
      ),
      children: [],
    },
    {
      path: "create-site",
      breadcrumb: {
        display: "Create Site",
      },
      element: (
        <ProtectedElement
          element={SiteCreate}
          allowedGroups={[SUPER_ADMIN, LOCUS_CONFIGURATION_ADMIN]}
        />
      ),
    },
    {
      path: "site-info",
      element: (
        <ProtectedElement
          element={SiteOverview}
          allowedGroups={[
            LOCUS_TENANT,
            CROSS_SITE_VIEWER,
            VIEWER,
            CONFIGURATION_ADMIN,
            LOCUS_CONFIGURATION_ADMIN,
          ]}
        />
      ),
      breadcrumb: {
        display: "Site Info",
      },
    },
    {
      path: "overview-dashboard",
      element: (
        <ProtectedElement
          element={OverviewDashboard}
          allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
        />
      ),
      breadcrumb: {
        display: "Site Overview",
      },
    },
    {
      path: "dashboards",
      children: [
        {
          path: "performance",
          element: (
            <ProtectedElement
              element={Dashboard}
              allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
            />
          ),
        },
        {
          path: "visibility",
          element: (
            <ProtectedElement
              element={Dashboard}
              allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
            />
          ),
        },
        {
          path: "management",
          element: (
            <ProtectedElement
              element={Dashboard}
              allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
            />
          ),
        },
      ],
    },
    {
      path: "reports",
      children: [
        {
          path: "performance",
          element: (
            <ProtectedElement
              element={Dashboard}
              allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
            />
          ),
        },
        {
          path: "visibility",
          element: (
            <ProtectedElement
              element={Dashboard}
              allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
            />
          ),
        },
        {
          path: "management",
          element: (
            <ProtectedElement
              element={Dashboard}
              allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
            />
          ),
        },
      ],
    },
    UsersRoutes,
    PermissionsRoutes,
    AssetManagementRoutes,
    ToolsRoutes,
    HelpRoutes,
  ],
};
