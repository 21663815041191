// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "axios"; // this does not include app headers
import axiosServices from "../../utils/axios";
import { dispatch } from "../index";
import { storage } from "../../utils/helpers/storage";
import { SET_SELECTED_CLIENT, SET_SELECTED_SITE } from "../actions";
import { jwtDecode } from "jwt-decode";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  customers: { count: 0, rows: [] },
  selectedClient: null,
  selectedSite: storage.getSite() ? JSON.parse(storage.getSite()) : null,
  selectedSiteConfig: null,
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CUSTOMERS
    getCustomersSuccess(state, action) {
      state.customers = action.payload;
    },

    // SET SELECTED User
    getSelectedClientSuccess(state, action) {
      if (action.payload) {
        storage.setRegion(action.payload?.region || "eus");
        storage.setDomain(action.payload?.domain || "");
        storage.setClientId(action.payload?.id || "");
        storage.setSSOIdpUrl(action.payload?.ssoIdpUrl || "");
        storage.setSSOIdpClient(action.payload?.ssoIdpClient || "");

        axiosServices.defaults.headers["locus-domain"] = storage.getDomain()
          ? storage.getDomain()
          : null;
        axiosServices.defaults.headers["locus-region"] = storage.getRegion()
          ? storage.getRegion()
          : null;
      }
      let _sites = [];

      if (action?.payload?.sites) {
        _sites = action?.payload?.sites
          .filter((site) => site.status !== "Decommissioned")
          .sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));

        let decommisionedSites = action?.payload?.sites
          .filter((site) => site.status === "Decommissioned")
          .sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));

        action.payload.sites = _sites.concat(decommisionedSites);
      }
      state.selectedClient = action.payload;
    },

    // SET SELECTED SITE
    setSelectedSite(state, action) {
      if (action.payload) {
        storage.setSite(JSON.stringify(action.payload));
      } else {
        storage.setSite("");
      }
      state.selectedSite = action.payload;
    },
    setSelectedSiteConfig(state, action) {
      state.selectedSiteConfig = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCustomers({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
}) {
  return async () => {
    let params = {};
    if (pageNumber) {
      params.page = pageNumber;
    }
    if (pageSize) {
      params.pageSize = pageSize;
    }
    if (sortField) {
      params.sort = sortField;
    }
    if (sortDirection) {
      params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
    }
    if (searchFilter) {
      // params.filter = searchFilter;
      params.name = searchFilter;
    }
    try {
      const decoded = storage.getAccessToken()
        ? jwtDecode(storage.getAccessToken())
        : null;
      const response = await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/client`,
        {
          params,
          headers: {
            "locus-domain": decoded?.domain,
            "locus-region": storage.getUserRegion()
              ? storage.getUserRegion()
              : storage.getRegion(),
          },
        },
      );
      dispatch(slice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      dispatch(
        slice.actions.getCustomersSuccess({
          count: 0,
          rows: [],
        }),
      );
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomer(id) {
  return async () => {
    try {
      const response = await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/client/${id}`,
      );
      dispatch(slice.actions.getSelectedClientSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function findClient(domain) {
  domain = domain ? domain : storage.getDomain();
  return async () => {
    try {
      const response = await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/client/find?domain=${domain}`,
      );
      dispatch(slice.actions.getSelectedClientSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getClientImageUploadToken(clientId, filename) {
  return async () => {
    try {
      return await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/client/${clientId}/upload`,
        {
          params: {
            filename: filename,
          },
        },
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function uploadClientImage(token, file) {
  return async () => {
    try {
      return await axios.put(`${token.combinedUrl}`, file, {
        headers: {
          "Content-Type": file.type,
          "x-ms-blob-type": "BlockBlob",
          "x-ms-meta-contenttype": file.type,
          "x-ms-meta-originalfile": file.name,
          "x-ms-meta-filetype": file.type,
        },
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function createCustomer(data) {
  return async () => {
    try {
      return await axiosServices.post(
        `${process.env.REACT_APP_SERVICES_API}/services/client`,
        data,
        {
          headers: {
            // "locus-region-original": data.region,
            "locus-region-original": storage.getUserRegion(),
            "locus-region": data.region,
          },
        },
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function updateCustomer(data) {
  return async () => {
    try {
      let clientId = data.id || null;
      delete data?.id;
      return await axiosServices.put(
        `${process.env.REACT_APP_SERVICES_API}/services/client/${clientId}`,
        data,
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function addSiteToClient(data, id) {
  return async () => {
    try {
      return await axiosServices.patch(
        `${process.env.REACT_APP_SERVICES_API}/services/client/${id}/append`,
        data,
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function createSite(data) {
  return async () => {
    try {
      return await axiosServices.post(
        `${process.env.REACT_APP_SERVICES_API}/services/site`,
        data,
        {
          headers: {
            "locus-region-original": storage.getUserRegion(),
            "locus-domain": "locus",
          },
        },
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function setSelectedClient(customer) {
  return async () => {
    try {
      dispatch({
        type: SET_SELECTED_CLIENT,
        payload: customer,
      });
      dispatch(slice.actions.getSelectedClientSuccess(customer));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedSite(site) {
  return async () => {
    try {
      dispatch({
        type: SET_SELECTED_SITE,
        payload: site,
      });
      dispatch(slice.actions.setSelectedSite(site));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function decommissionSite(id) {
  return await axiosServices.delete(
    `${process.env.REACT_APP_SERVICES_API}/services/site/${id}`,
  );
}

export async function getSiteSyncTime(legacySiteId) {
  return await axiosServices.get(
    `${process.env.REACT_APP_SERVICES_API}/services/warehouse/sync/time?legacySiteId=${legacySiteId}`,
  );
}

export function getSiteConfig(id) {
  return async (dispatch) => {
    try {
      const response = await axiosServices.get(
        `${process.env.REACT_APP_API}/config/config/target/${id}`,
      );
      dispatch(slice.actions.setSelectedSiteConfig(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllSiteConfig(id) {
  return async (dispatch) => {
    try {
      const response = await axiosServices.get(
        `${process.env.REACT_APP_API}/config/config/target`,
      );
      
      // Check the response
      const { rows } = response.data;

      // Loop through rows to find the matching site.id
      const matchingRow = rows.find((row) => row.site.id === id);

      if (matchingRow) {
        // If found, call getSiteConfig with the row's _id
        dispatch(getSiteConfig(matchingRow._id));
      } else {
        // If not found, set selected site config to null
        dispatch(slice.actions.setSelectedSiteConfig(null));
      }

      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateSiteConfig(id, params) {
  return async (dispatch) => {
    try {
      const response = await axiosServices.put(
        `${process.env.REACT_APP_API}/config/config/target/${id}`,
        params 
      );
      dispatch(slice.actions.setSelectedSiteConfig(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

