import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import Logo from "components/Logo/Logo";
import { useLocusRouter } from "../hooks/useLocusRouter";

// ==============================|| MAIN LOGO ||============================== //

export const LogoSection = () => {
  const { getHomeRoute } = useLocusRouter();
  return (
    <Link
      component={RouterLink}
      to={getHomeRoute()}
      aria-label="Locus Robotics Logo"
    >
      <Logo />
    </Link>
  );
};
