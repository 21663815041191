import { Loadable } from "../../components/Loadable/Loadable";
import { lazy } from "react";
import componentLoader from "../../utils/helpers/componentLoader";

const KnowledgeBase = Loadable(
  lazy(() => componentLoader(() => import("views/help/KnowledgeBase"))),
);
const ReleaseInfo = Loadable(
  lazy(() => componentLoader(() => import("views/help/ReleaseInfo"))),
);
const ServiceHealth = Loadable(
  lazy(() => componentLoader(() => import("views/help/ServiceHealth"))),
);

export const HelpRoutes = {
  path: "help",
  breadcrumb: {
    display: "Help",
  },
  children: [
    {
      path: "knowledge-base",
      breadcrumb: {
        display: "Knowledge Base",
      },
      element: <KnowledgeBase />,
    },
    {
      path: "release-info",
      breadcrumb: {
        display: "Release Info",
      },
      element: <ReleaseInfo />,
    },
    {
      path: "service-health",
      breadcrumb: {
        display: "Service Health",
      },
      element: <ServiceHealth />,
    },
  ],
};
