import { UserAuthentication } from "./UserAuthentication";
import {
  InMemoryWebStorage,
  UserManager,
  WebStorageStateStore,
} from "oidc-client-ts";
import { storage } from "../../utils/helpers/storage";

export const makeUserAuthentication = () => {
  let userManagerSettings = {
    //Locus dependant
    authority: storage.getSSOIdpUrl() || null,
    client_id: storage.getSSOIdpClient() || null,
    redirect_uri: window.location.origin + "/login/sso",
    post_logout_redirect_uri: window.location.origin + "/login",
    popup_post_logout_redirect_uri: window.location.origin + "/login",
    response_type: "code",
    scope: "openid email profile phone",
    automaticSilentRenew: true,
    validateSubOnSilentRenew: true,
    response_mode: "query", // In v3.0 default of response_mode changed from query → undefined

    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
  };

  const userManager = new UserManager(userManagerSettings);
  return new UserAuthentication(userManager);
};
