// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import { dispatch } from "../index";
import { onSetSelectedClient } from "../actions";

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  robots: { count: 0, rows: [] },
  selectedRobot: {},
};

const slice = createSlice({
  name: "robot",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getRobotsSuccess(state, action) {
      state.robots = action.payload;
    },

    // SET SELECTED Robot
    getSelectedRobotSuccess(state, action) {
      state.selectedRobot = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onSetSelectedClient, (state, action) => {
        state.robots = initialState.robots;
      })
      .addDefaultCase((state, action) => {});
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRobots({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedClient,
  selectedSite,
  viewState,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }
      if (searchFilter) {
        // params.filter = searchFilter;
        params.name = searchFilter;
      }
      if (viewState === "client" && selectedClient && selectedClient?.id) {
        params.client = selectedClient.id;
      }
      if (viewState === "site" && selectedSite && selectedSite?.id) {
        params.site = selectedSite.id;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/robot`,
        {
          params,
        },
        {}
      );
      dispatch(slice.actions.getRobotsSuccess(response.data));
    } catch (error) {
      dispatch(
        slice.actions.getRobotsSuccess({
          count: 0,
          rows: [],
        })
      );
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRobot(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/robot/${id}`,
        {},
        {}
      );
      dispatch(slice.actions.getSelectedRobotSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createRobot(data) {
  return async () => {
    try {
      console.log("createRobot", data);
      // const response = await axios.post(
      //     `${process.env.REACT_APP_SERVICES_API}/robot`,
      //     {
      //         "robotname": data.robotname,
      //         "email": data.email,
      //         "phone": data.phone || '',
      //         "timezone": data.timezone,
      //         "domain": localStorage.getItem('domain')
      //     },
      //     {}
      // );
      // console.log('createRobot - response', response);
      // dispatch(slice.actions.getSelectedRobotSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearRobots() {
  return async () => {
    try {
      dispatch(slice.actions.getSelectedRobotSuccess(initialState.robots));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
