import { memo, useEffect, useMemo } from "react";

import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";
import menuItem from "menu-items";
import { NavGroup } from "./NavGroup";
import useConfig from "hooks/useConfig";
import { MenuItems } from "menu-items/MenuItems";
import LAYOUT_CONST from "constant";
import { HORIZONTAL_MAX_ITEM } from "config";
import { useSelector } from "store";
import { showMenuItem } from "../../../routes/ProtectedRoute";
import { useLocation } from "react-router-dom";

// ==============================|| SIDEBAR MENU LIST ||============================== //

export const MenuList = memo(function MenuList() {
  const { viewState, activeUser, locus } = useSelector((state) => state.user);
  const theme = useTheme();
  const { layout } = useConfig();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const getMenu = MenuItems();

  const handlerMenuItem = () => {
    const isFound = menuItem.items.some((element) => {
      if (element.id === "widget") {
        return true;
      }
      return false;
    });

    if (getMenu?.id !== undefined && !isFound) {
      menuItem.items.splice(1, 0, getMenu);
    }
  };

  const setItemView = (navItem, viewState, activeUser, locus) => {
    navItem.className = showMenuItem({ activeUser, viewState, navItem })
      ? navItem?.className !== "d-none"
        ? navItem.className || navItem.classNameGuidedTour
        : ""
      : "d-none";
  };

  const hasVisibleChildren = (children) => {
    return children.filter((x) => x.className !== "d-none").length > 0;
  };

  const filterNavChildItems = (items, viewState, activeUser, locus) => {
    return items.filter((navItem) => {
      // check for children
      if (navItem?.children?.length) {
        filterNavChildItems(navItem.children, viewState, activeUser, locus);
      }
      /* show `collapse` items if they have allowed children */
      if (navItem?.type === "collapse") {
        navItem.className = hasVisibleChildren(navItem.children)
          ? ""
          : "d-none";
      } else {
        setItemView(navItem, viewState, activeUser);
      }
    });
  };

  const filterNavItems = (items, viewState, activeUser, locus) => {
    return items.filter((navItem) => {
      navItem.props.item.className = navItem.props?.className;
      //check for children
      if (navItem.props?.item?.children?.length) {
        filterNavChildItems(
          navItem.props.item.children,
          viewState,
          activeUser,
          locus,
        );
      }
      /* show `group` items only if they have allowed children */
      if (navItem.props?.item?.type === "group") {
        return hasVisibleChildren(navItem.props.item.children) ? navItem : null;
      }
      return showMenuItem({
        activeUser,
        viewState,
        navItem: navItem.props?.item,
      })
        ? navItem
        : null;
    });
  };

  useEffect(() => {
    handlerMenuItem();
    // eslint-disable-next-line
  }, []);

  // last menu-item to show in horizontal menu bar
  const lastItem =
    layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
      ? HORIZONTAL_MAX_ITEM
      : null;

  let lastItemIndex = menuItem.items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItem.items.length) {
    lastItemId = menuItem.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItem.items
      .slice(lastItem - 1, menuItem.items.length)
      .map((item) => ({
        title: item.title,
        elements: item.children,
      }));
  }

  let navItems = menuItem.items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case "group":
        return (
          <NavGroup
            key={item.id}
            item={item}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
            className={item.className}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const location = useLocation();

  const menuItems = useMemo(() => {
    return filterNavItems(navItems, viewState, activeUser, locus);
  }, [location, viewState]);
  return <>{menuItems}</>;
});

// export default memo(MenuList);
