import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusUpdateConfigurationFilesIcon = ({ sx }) => {
  return (
    <SvgIcon sx={sx}>
      <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50" 
        viewBox="0 0 50 50" 
        fill="currentColor" 
      >
        <rect width="50" height="50" rx="8" fill="#0078CF" />
        <path 
          d="M13.9527 36.3182C13.278 36.3182 12.7004 36.0778 12.22 35.5972C11.7395 35.1165 11.4993 34.5386 11.4993 33.8636V19.1364C11.4993 18.7886 11.6168 18.4972 11.8519 18.2619C12.0871 18.0267 12.3784 17.9091 12.726 17.9091C13.0735 17.9091 13.3649 18.0267 13.6 18.2619C13.8351 18.4972 13.9527 18.7886 13.9527 19.1364V33.8636H33.5798C33.9273 33.8636 34.2187 33.9813 34.4538 34.2165C34.6889 34.4517 34.8064 34.7432 34.8064 35.0909C34.8064 35.4386 34.6889 35.7301 34.4538 35.9653C34.2187 36.2006 33.9273 36.3182 33.5798 36.3182H13.9527ZM18.8594 31.4091C18.1847 31.4091 17.6072 31.1688 17.1267 30.6881C16.6463 30.2074 16.406 29.6295 16.406 28.9545V15.4545C16.406 14.7795 16.6463 14.2017 17.1267 13.721C17.6072 13.2403 18.1847 13 18.8594 13H23.9809C24.308 13 24.6198 13.0614 24.9162 13.1841C25.2127 13.3068 25.4734 13.4807 25.6982 13.7057L27.4463 15.4545H36.0331C36.7078 15.4545 37.2854 15.6949 37.7658 16.1756C38.2463 16.6562 38.4865 17.2341 38.4865 17.9091V28.9545C38.4865 29.6295 38.2463 30.2074 37.7658 30.6881C37.2854 31.1688 36.7078 31.4091 36.0331 31.4091H18.8594Z" 
          fill="#F9F9F9"
        />
      </svg>
    </SvgIcon>
  );
};
