import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LocusDialog } from "../LocusDialog/LocusDialog";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { editUser } from "../../store/slices/user";
import { dispatch } from "../../store";

export const UserRoleCheckDialog = () => {
  const { activeUser, warehouseRoles } = useSelector((state) => state.user);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedRole, setSelectedRole] = useState(false);
  const noRoleMessage = `Please select a user type that best describes your position in the business:`;

  const handleRoleSelection = async () => {
    setShowDialog(false);
    await dispatch(editUser(activeUser.userId, { clientRole: selectedRole }));
  };

  useEffect(() => {
    if (activeUser) {
      if (activeUser?.type === "human" && !activeUser.clientRole) {
        setShowDialog(true);
      } else {
        setShowDialog(false);
      }
    }
  }, [activeUser]);

  return (
    <>
      {showDialog && (
        <LocusDialog title={"No User Type Assigned"} open={showDialog}>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "inherit",
            }}
          >
            <DialogContentText id="alert-dialog-description" mb={2}>
              {noRoleMessage}
            </DialogContentText>

            <Box
              noValidate
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              xs={12}
            >
              <Typography variant="subtitle1">User Type</Typography>
              <Autocomplete
                id="warehouse-role-combo-box"
                options={warehouseRoles.filter((item) => {
                  if (
                    !activeUser?.email?.toLowerCase().includes("@locusrobotics")
                  ) {
                    return item !== "Locus";
                  } else {
                    return item;
                  }
                })}
                sx={{ minWidth: 300 }}
                onChange={(event, newValue) => setSelectedRole(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              px={3}
            >
              <Button
                onClick={handleRoleSelection}
                variant="contained"
                disableElevation
                disabled={!selectedRole}
              >
                Continue
              </Button>
            </Grid>
          </DialogActions>
        </LocusDialog>
      )}
    </>
  );
};
